import _ from 'lodash/fp'
import React from 'react'
import {formatRecord, isDev} from './utils'
import {processAllFiles} from './fileProcess/processAllFiles'
import {getFileInstanceList} from "../dicom/FileEventToList";
import {Canceled, Deleted, FileNotFound, Shortlisted, Waiting} from "../enums/FileState";
import {GroupAction} from "./GroupAction";
import {updateActivityLogBy, updateActivityLogFile} from "../components/hooks/useActivityLog";
import {NonCompliantDicom} from "../enums/RecordFormat";
import { syncControlLock, syncProcessLock, syncUploadLock } from './queues';

const FAST_MODE_FAKE_LOCK = {value: [_.identity]}

const updateInfo = (path) => async ({recordManager, config}, record, value) => {
  const update = _.set(path, value)
  if (record.recordUID) {
    await config.persistRecordInfo(update(record), record)
    recordManager.updateRecord(record,update)
  } else {
    recordManager.updateRecord(record,update)
  }
}

export const UploaderAction = {
  RemoveFromToBeUploaded : ({setData}, n) => {
    setData(_.update('toBeUploaded', (i) => i - n))
  },
  RemoveFromTotalRecords: ({setData}, n) => {
    setData(_.update('totalRecords', (i) => i - n))
  },
  Retry: ({recordManager, uploader}, record) => {
    recordManager.queueToRetry(record)
    uploader.start()
  },
  RetryFilesNotFound: ({recordManager, uploader}) => {
    recordManager.retryByFailure(FileNotFound)
    uploader.start()
  },
  RetryFile: ({recordManager, uploader},record,file) => {
    recordManager.queueToRetry(record,file)
    uploader.start()
  },
  // Delete DICOMs while processing
  DeleteDicom: ({getData, setData, recordManager, uploader, doAction}, record) => {
    // const prevCanceledDicomUIDs = getData('canceledDicomUIDs')
    // setData(_.update('canceledDicomUIDs', prevCanceledDicomUIDs.concat(record.studyUID)))
    const prevCanceledDicomUIDs = JSON.parse(localStorage.getItem('canceledDicomUIDs')) || []
    localStorage.setItem('canceledDicomUIDs', JSON.stringify(prevCanceledDicomUIDs.concat(record.studyUID)))
    doAction(UploaderAction.Delete, record)
  },
  Delete: async ({recordManager, uploader, doAction}, record) => {
    await doAction(UploaderAction.DeleteMany, [record])
  },
  DeleteMany: async ({
                       recordManager,
                       doAction,
                       uploader,
                       config: {removeSelectedRecords, setLinkedStudies}
                     }, recordRows) =>
    uploader.doWithoutCallingNext(async () => {
      const records = recordRows.find(_.matchesProperty('key',NonCompliantDicom))
        ? recordRows
          .filter(_.negate(_.matchesProperty('key',NonCompliantDicom)))
          .concat(recordManager.getRecords().filter(_.matchesProperty('recordFormat',NonCompliantDicom)))
        : recordRows
      const canceledRecordsUIDs = await Promise.all(records.map(record => uploader.cancelRecord(record).then(uid => uid || record.recordUID)))
      const recordsUIDs = canceledRecordsUIDs.filter(Boolean)
      if (recordsUIDs.length) {
        await removeSelectedRecords(recordsUIDs)
      }
      records.forEach(recordManager.removeRecord)
      records.forEach(record => updateActivityLogBy('key', record.key, Deleted))
      const studyUIDS = records.map(_.get('studyUID'))
      setLinkedStudies(_.filter(item => !studyUIDS.includes(item)))
      doAction(GroupAction.RemoveInvalidItems)
    }),
  DeleteManyByUID: async ({recordManager, doAction, uploader, config: {removeSelectedRecords}}, recordsUIDs) => {
    doAction(UploaderAction.DeleteMany,recordManager.getRecords().filter(({recordUID,recordFormat}) => recordsUIDs.includes(recordUID) || recordsUIDs.includes(recordFormat)))
  },
  CancelQueue: async ({uploader}) => {
    return uploader.cancelQueue()
  },
  CancelRecord: ({uploader,recordManager},record) =>
    uploader.doWithoutCallingNext(async () => {
      await uploader.cancelRecord(record)
      recordManager.cancelRecord(record)
      updateActivityLogBy('key', record.key, Canceled)
    }),
  CancelSingleFile: ({uploader,recordManager},record,file) =>
    uploader.doWithoutCallingNext(async () => {
      await uploader.cancelFile(file)
      recordManager.removeFile(record,file)
    }),
  Upload: async ({setData, getData, setError, recordManager, uploader, doAction, config}, event) => {
    function calculateReadSpeed(
        file, 
        chunkSize = 1048576
      ) { // default chunk size 1MB
        // chunkSize = 1048576 * 10 // 10MB
        // chunkSize = 524288  // 0.5MB
      return new Promise((resolve, reject) => {
          const startTime = performance.now();
          const reader = new FileReader();

          const size = Math.min(file.size, chunkSize);
  
          // Read only the first chunk of the file
          const blob = file.size > chunkSize ? file.slice(0, size) : file;
          
          reader.onloadend = () => {
              const endTime = performance.now();
              const timeTaken = (endTime - startTime) / 1000; // time in seconds
              const sizeInMB = size / (1024 * 1024); // size of the chunk in Megabytes
              const speedMBps = sizeInMB / timeTaken; // speed in MB/s for the chunk
  
              resolve({speed: speedMBps, size: size / (1024 * 1024)});
          };
  
          reader.onerror = reject;
  
          reader.readAsArrayBuffer(blob);
      });
    }
    
    localStorage.removeItem('canceledDicomUIDs')
    var slowMode = getData('slowMode')
    setData(_.update('isProcessing', i => i + 1))
    let allErrors = []
    const fileInstances = await getFileInstanceList(event)
    if (event.target && event.target.value) {
      event.target.value = ""
    }
    var sampleList = fileInstances.length <= 10 ? fileInstances : fileInstances.sort((a,b) => b.file.size - a.file.size).slice(0,10)
    
    const speeds = await Promise.all(
      sampleList.map(instance => calculateReadSpeed(instance.file))
    );
    
    // const averageSpeed = speeds.reduce((a, b) => a + b.speed, 0) / speeds.length;
    // Assuming speeds is an array of objects with properties 'speed' and 'size'
    const totalSize = speeds.reduce((total, current) => total + current.size, 0);
    const sizeWeightedAverageSpeed = speeds.reduce((total, current) => {
        return total + (current.speed * current.size);
    }, 0) / totalSize;
    const SPEED_DISK_READER = window.SPEED_DISK_READER || 1;
    console.log(`Size-Weighted Average Speed: ${sizeWeightedAverageSpeed.toFixed(2)} MB/s`);
    
    // Conditions to enter slow mode
    if((sizeWeightedAverageSpeed < SPEED_DISK_READER && fileInstances.length > 10) || (isDev)){
      console.log('Slow mode')
      setData(_.set('slowMode', true))
      slowMode = true
    }else{
      console.log('Fast mode')
      setData(_.set('slowMode', false))
      slowMode = false
    }
    
    let unprocessedFiles = fileInstances
    setData(_.update('unprocessedFiles', (i = 0) => i + fileInstances.length))
    
    // If slow mode (CD or DVD):
    // Wait for control lock release before processing files
    const {
      value: [unlockControl],
    } = await (slowMode ?  syncControlLock.next() : FAST_MODE_FAKE_LOCK)
    try{
      var pendingUploadRecords = [] // Group files belonging to the same DICOM before starting upload
      /* eslint-disable no-restricted-syntax */
      for await (const [processedRecords, errors, processedFiles] of processAllFiles(fileInstances,config.adminUserEmail,slowMode)) {
        // const records = processedRecords 
        // TODO const records = processedRecords.filter(not deleted)
        // const canceledDicomUIDs = getData('canceledDicomUIDs')
        // console.log(canceledDicomUIDs) // Always print empty - seems it is not able to get updated data from getData
        // const records = processedRecords.filter(record => !canceledDicomUIDs.includes(record.studyUID))
        const canceledDicomUIDs = localStorage.getItem('canceledDicomUIDs') ? JSON.parse(localStorage.getItem('canceledDicomUIDs')) : []
        const records = processedRecords.filter(record => !canceledDicomUIDs.includes(record.studyUID)) // Remove canceled records
        unprocessedFiles = _.difference(unprocessedFiles, processedFiles)
        
        const hasDicom = records.some(record => record.recordFormat === 'DicomStudy');
        pendingUploadRecords = [...pendingUploadRecords, ...records];

        const shouldStartUpload = hasDicom 
          ? pendingUploadRecords.length && (records[0]?.key !== pendingUploadRecords[0]?.key || unprocessedFiles.length === 0)
          : pendingUploadRecords.length > 0;

        // TODO - Groupping Dicoms before Start Upload
        if (shouldStartUpload) {
          let firstRecordKey = hasDicom ? pendingUploadRecords[0]?.key : null;
          let recordsToUpload = hasDicom && unprocessedFiles.length > 0
            ? pendingUploadRecords.filter(record => record.key === firstRecordKey) 
            : pendingUploadRecords;
          
          recordsToUpload.map(record => {
              record.files.forEach(instance => updateActivityLogFile(instance.file,Waiting,record))
              record?.studyUID ? config.setLinkedStudies(linkedStudies => linkedStudies.concat(record.studyUID)) : null
          })
          recordManager.insertMany(recordsToUpload.map(_.set('mode',config.mode)))
          doAction(GroupAction.SetupNewRecordsGroups, recordsToUpload)
          config.setTotal(i => i + recordsToUpload.length)
          config.setToBeUploaded(i => i + recordsToUpload.length)
          uploader.start()
          // Remove records that already started to upload from pendingUploadRecords. 
          // Sometimes the last dcm could not belong to the same study. Then it must be kept to upload
          pendingUploadRecords = hasDicom 
            ? pendingUploadRecords.filter(record => record.key !== firstRecordKey)
            : [];
        }

        setData(_.update('unprocessedFiles', (i) => i - processedFiles.length))
        setData(_.update('processedFiles', (i = 0) => i + processedFiles.length))
        if (errors.length) {
          errors.map(item => updateActivityLogFile(item.file,Shortlisted,item))
          allErrors = allErrors.concat(errors)
        }
        const canceled = await getData("dialogs.cancelProcessing")?.promise
        if (canceled) {
          setData(_.update('unprocessedFiles', (i) => 0))
          setData(_.update('processedFiles', (i = 0) => 0))
          setData(_.set('isProcessing', 0))
          setData(_.set('unprocessedFiles', 0))
          return;
        }
      }
    }
    // release control lock
    finally{
      unlockControl()
    }
    if (allErrors.length >= 1) {
      if(allErrors.length === 1){
        //setError("fileType", unprocessedFiles[0].filename + " could not be uploaded. File type not supported.")
        setError("fileType", allErrors[0]?.filename + '.' + allErrors[0]?.extension + " could not be uploaded. File type not supported.")
      } else {
        setError("fileType", allErrors.length + " files could not be uploaded due to file types not being supported.")
      }
    }
    // Clean the list of records that were deleted while processing. 
    // setData(_.set('canceledDicomUIDs', []))
    localStorage.removeItem('canceledDicomUIDs')
    setData(_.update('isProcessing', i => i - 1))
    if(getData('isProcessing') <= 0){
      setData(_.update('unprocessedFiles', (i) => 0))
      setData(_.update('processedFiles', (i = 0) => 0))
    }
  },
  SetNotes: updateInfo('notes.note'),
  SetMetadata: updateInfo('metadata'),
  SetDescription: updateInfo('description'),
  LinkVivaStudyAsRecord: ({recordManager, doAction}, study) => {
    const record = formatRecord(study)
    recordManager.addRecord(record)
    doAction(GroupAction.SetupNewRecordsGroups, [record])
  },
  LinkCarequalityFileAsRecord: ({recordManager, doAction}, file) => {
    const record = formatRecord(file)
    recordManager.addRecord(record)
    // doAction(GroupAction.AddCareQualityGroup)
    doAction(GroupAction.AddCareQualityGroupWithFile,record)
    // doAction(GroupAction.SimpleMoveByRecordUID, file.recordUID, 'notclass', 'Carequality')
  },
  SetRecords: ({recordManager},updater) => {
    recordManager.setRecords(updater)
  },
  AddItemToCompletedRecords: ({setData},recordKey) => {
    // doAction(UploaderAction.AddItemToCompletedRecords,recordKey)
    setData(_.update('completedRecords', (i) => i.concat(recordKey)))
  },
  AddItemToCompletedHidden: ({setData},recordKey) => {
    setData(_.update('completedHidden', (i) => i.concat(recordKey)))
  },
  ClearCompleted: ({setData}) => {
    setData(_.set('completedRecords', []))
    setData(_.set('completedHidden', []))
  },
  getRecordsUID: ({  recordManager }, recordRows) => {
    // Needed to get each record UID for the download many action, when there are nonCompliant files
    const records = recordRows.find(_.matchesProperty('key',NonCompliantDicom))
      ? recordRows
        .filter(_.negate(_.matchesProperty('key',NonCompliantDicom)))
        .concat(recordManager.getRecords().filter(_.matchesProperty('recordFormat',NonCompliantDicom)))
        // .filter(record => !record.quarantined)) // Removing quarantined records from the list of records to download
      : recordRows
    return records.map(record => record.recordUID)
  }
}
