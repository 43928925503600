import {Button, ContextMenu, Icon, Li, Loading, willUseIsSticky } from '@startlibs/components';
import {usePopupToggle, useToggle} from '@startlibs/core'
import {_s, getColor } from '@startlibs/utils';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {PurviewFooter} from '../../components/PurviewFooter'
import {Card, PageContainer} from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt'
import {getDate} from '../../utils/utils';
import { BarChartCasesByState } from './analytics/BarChartCasesByState';
import { MapCasesByState } from './analytics/MapCasesByState';
import { DonutChart } from './analytics/DonutChart';
import { AreaChartMultiple } from './analytics/AreaChartMultiple';
import { jwtGetFetcher } from '../../utils/authFetch';
import { CountryRegionData } from 'react-country-region-selector';
import { AdvancedSearchPopup } from './analytics/AdvancedSearchPopup';
import { AreaChartFees } from './analytics/AreaChartFees';
import { StarsRatingDisplay } from './analytics/StarsRatingDisplay';
import { AreaChartVolume } from './analytics/AreaChartVolume';
import { DonutChartPayment } from './analytics/DonutChartPayment';
import { darken } from 'polished';

export const SectionTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
`

const Text = styled.p`
  font-size: 16px !important;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
`

const ContentComponent = styled.div`
  // margin-top: 2rem;
  display: inline-block;
`

const LineComponent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  ${Button}{
    margin-top: 13px;
    margin-left: 0.5rem;
  }
  
  div{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  div:first-of-type {
    /* Add your specific styles for the first div here */
    margin-left: 0rem;
    margin-right: 0.5rem;
  }
`

const DateSelector = styled.div`
  background: ${getColor('gray240')};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
`

const PresetsButton = styled.div`
  padding: 1rem 1.5rem 1rem 1.75rem;
  border-radius: 8px 0 0 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  :hover {
    background: ${props => darken(0.025, getColor('gray240')(props))};
  }
  :active {
    background: ${props => darken(0.05, getColor('gray240')(props))};
  }
  ${Icon} {
    font-size: 12px;
    margin-left: 0.75rem;
    padding-top: 1px;
  }
`
const DateButton = styled.div`
  padding: 1rem 1.75rem 1rem 1.5rem;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  position: relative;
  margin-left: 1px;
  display: flex;
  align-items: center;
  :hover {
    background: ${props => darken(0.025, getColor('gray240')(props))};
  }
  :active {
    background: ${props => darken(0.05, getColor('gray240')(props))};
  }
  :after {
    content: '';
    border-left: 1px solid ${getColor('gray210')};
    left: -1px;
    top: 1rem;
    bottom: 1rem;
    position: absolute;
  }
  ${Icon} {
    font-size: 22px;
    margin-right: 1rem;
  }
`

const CountBadgesContainer = styled.div`
  display: flex;
  margin: 3rem 0;
  justify-content: center;
`

const CountBadges = styled.span`
  color: ${props => props.color };
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0 0.75rem;
  border-radius: 6px;
  background: ${props => props.background };
`

const AnalyticsHeader = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0.25rem 0;
    font-size: 13px;
  }
  position: sticky;
  top: 4rem;
  z-index: 10;
  ${props => props.isSticky && `
    padding-bottom: 1rem;
    transition: 0.5s ease;
    box-shadow: 0 4px 4px -1px rgba(0,0,0,0.15);
    margin-bottom: 3.5rem !important;
  `}
  ${SectionTitle} {
    margin-bottom: 0;
  }
`

const PeriodicitySeletor = styled.span`
  cursor: pointer;
  position: relative;
  .label {
    text-decoration: underline;
  }
  ${Icon} {
    font-size: 15px;
    margin-left: 0.5rem;
  }
  :hover {
    color: ${getColor('main')};
  }
  :active {
    color: ${props => darken(0.025, getColor('main')(props))};
  }
`

const ChartsGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const NotEnoughData = styled.div`
  display: inline-block;
  text-align: center;
  background: ${getColor('gray240')};
  padding: 1.5rem 2rem;
  border-radius: 8px;
  font-size: 14px;
  margin: 2rem auto;
`

const proccessPaymentsGroupByCategory = (data) => {
  const result = [];
  Object.entries(data).forEach(([categoryName, objData]) => {
    result.push({ categoryName: categoryName, totalAmount: objData.totalAmount, percentage: objData.percentage });
  });
  return result;
}
const proccessCaseVolume = (data, groupPeriod, useFiscalYear) => {
  // Helper function to map month to its quarter
  var limit = 48;
  const result = [];
  const periodData = {};

  if(groupPeriod == "MONTH" || groupPeriod == "YEAR"){
    Object.entries(data.statistics).forEach(([p, data]) => {
      if (Object.keys(data).length !== 0) {
        const period = groupPeriod === "YEAR" 
          ? `${p}` 
          : groupPeriod === "QUARTER" 
            ? `${p.split(' ')[1]} ${p.split(' ')[0].slice(2, 4)}` 
            : p

        if (!periodData[period]) {
          periodData[period] = { completed: 0, archived: 0, total: 0 };
        }
      
        Object.entries(data).forEach(([caseType, count]) => {
            if (caseType === "CASE_ARCHIVED") {
              periodData[period].archived += count;
            } else if (caseType === "CASE_CLOSED" || caseType === "CASE_REVIEWED") {
              periodData[period].completed += count;
            }
            periodData[period].total += count;
        
        });
      }
    });

    Object.entries(periodData).forEach(([p, data]) => {
        result.push({ period: p, ...data });
    });  
    
  }else{
    // Quarter:
    data.map(record => {

      if(Object.keys(record.caseCountsByStatus).length > 0){
      
        const numberFromFiscalLabel = record.fiscalLabel.split(' ')[0];
        const yearFromFiscalLabel = record.fiscalLabel.split(' ')[1];
        const firstYear = yearFromFiscalLabel.split('-')[0];
        const secondYear = yearFromFiscalLabel.split('-')[1];

        const period = useFiscalYear 
          ? record.fiscalLabel 
          : (record.label == "Q1" || record.label == "Q2") 
            ? record.label + ` ${secondYear}` 
            : record.label +` ${firstYear}`

        if (!periodData[period]) {
          periodData[period] = { completed: 0, archived: 0, total: 0 };
        }

        Object.entries(record.caseCountsByStatus).forEach(([caseType, count]) => {
          if (caseType === "CASE_ARCHIVED") {
            periodData[period].archived += count;
          } else if (caseType === "CASE_CLOSED" || caseType === "CASE_REVIEWED") {
            periodData[period].completed += count;
          }
          periodData[period].total += count;
      
        });
      }
    })

    Object.entries(periodData).forEach(([p, data]) => {
      result.push({ period: p, ...data });
    });  
  }

  if(groupPeriod == "MONTH"){ 
    limit = 36;
  }
  if(groupPeriod == "QUARTER"){
    limit = 12;
  }
  if(groupPeriod == "YEAR"){
    limit = 12;
  }

  if(result.length > limit){
    return result.slice(-limit);
  }
  return result;
}

const parseDataSpecialtyStatus = (data, groupPeriod) => {
  let result = {};
  Object.keys(data).forEach(specialty => {
    let removable = true
    const summary = data[specialty].map(record => {
      const period = groupPeriod === "YEAR" 
        ? `${record.year}` 
        : groupPeriod === "QUARTER" 
          ? `${record.quarter} ${record?.year?.toString()?.slice(2, 4)}` 
          // : `${record.month?.split(' ')[0]?.slice(0,3) } ${record?.year?.toString()?.slice(2, 4)}`;
          : `${record.month}`;
      const completed = record.data.filter(item => item.status === "CASE_CLOSED").reduce((acc, curr) => acc + curr.count, 0);
      const archived = record.data.filter(item => item.status === "CASE_ARCHIVED").reduce((acc, curr) => acc + curr.count, 0);
      const total = record.data.reduce((acc, curr) => acc + curr.count, 0);
      if(record.data.length > 0){
        removable = false
      }
      return { period, completed, archived, total, removable };  
    });
    result[specialty] = summary.filter(item => !item.removable);
  });
  return result;
};


const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const count = (obj) => {
  return Object.keys(obj).length
}

const [WithIsSticky] = willUseIsSticky()

export const Analytics = () => {

  // options
  const useFullName = false
  const maxLimit = 30
  const useFiscalYear = false


  // data on state
  const [dataMap, setDataMap] = useState()
  const [geoBarChartData, setGeoBarChartData] = useState()
  const [casesBySpecialty, setCasesBySpecialty] = useState()
  const [categorySummary, setCategorySummary] = useState()
  const [casesBySpecialtyPeriod, setCasesBySpecialtyPeriod] = useState()
  const [volumeOfCases, setVolumeOfCases] = useState()
  const [paymentDonutChartData, setPaymentDonutChartData] = useState()
  const [ratings, setRatings] = useState()
  const [totals, setTotals] = useState({
    completed: null,
    archived: null,
    total: null
  })
  const [totalsVolume, setTotalsVolume] = useState({
    completed: null,
    archived: null,
    total: null
  })
  const [totalFees, setTotalFees] = useState(null)
  const [hoveredState, setHoveredState] = useState()

  // toggles
  const showMap = useToggle(false)
  const showCasesBySpecialty = useToggle(false)
  const showCategorySummary = useToggle(false)
  const showCasesBySpecialtyPeriod = useToggle(false)
  const showTotalFees = useToggle(false)
  const showRatings = useToggle(false)
  const showVolumeOfCases = useToggle(false)

  // Ajust Us states data
  const UsStatesData = CountryRegionData.filter(d => d[1] === "US")
  const UsStatesAbbr = UsStatesData
    .flatMap(d => {
      const list = d[2].split("|");
      // return list.map(item => item);
      return list.map(item => {
        const [stateName, stateAbbreviation] = item.split("~");
        return stateAbbreviation.trim();
      })
    });
  const UsStatesName = UsStatesData
    .flatMap(d => {
      const list = d[2].split("|");
      // return list.map(item => item);
      return list.map(item => {
        const [stateName, stateAbbreviation] = item.split("~");
        return stateName.trim();
      });
    })

  // Dates filters and selections
  const periodSelection = usePopupToggle()
  const datePicker = useToggle()

  const localStorageSelectedPeriod = localStorage.getItem('selectedPeriod')
  const [initialDate, setInitialDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedPeriod, setSelectedPeriod] = useState(localStorageSelectedPeriod ? localStorageSelectedPeriod : 'Last 12 months')

  const options = { month: 'short', day: '2-digit', year: 'numeric' };
  const currentYear = new Date().getFullYear();

  const periods = [
    'Last 7 days',
    'Last 4 weeks',
    'Last 3 months',
    'Last 12 months',
    'Month to date',
    'Quarter to date',
    'Year to date',
    'All time',
    'Custom'
  ]
  
  // Periods group
  const [period, setPeriod] = useState({label: 'Quarter', value: 'QUARTER'})
  const [periodFee, setPeriodFee] = useState({label: 'Quarter', value: 'QUARTER'})
  const [periodVolume, setPeriodVolume] = useState({label: 'Quarter', value: 'QUARTER'})
  const contextMenuPeriod = usePopupToggle()
  const contextMenuPeriodFee = usePopupToggle()
  const contextMenuPeriodVolume = usePopupToggle()

  const periodsGroup = [
    {label: 'Month', value: 'MONTH'},
    {label: 'Quarter', value: 'QUARTER'},
    {label: 'Year', value: 'YEAR'},
  ]

  const caseRequestsByspecialtyGroups = (initialDateUrl, endDateUrl) => {
    
    showCasesBySpecialtyPeriod.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/category-summary-group-by?start=${initialDateUrl}&end=${endDateUrl}&groupBy=${period.value}`)
      .then(res => {
        if(!isEmpty(res)){
          const parsedData = parseDataSpecialtyStatus(res, period.value)
          setCasesBySpecialtyPeriod(parsedData)
          let sumCompleted = 0;
          let sumArchived = 0;
          let sumTotal = 0;

          Object.values(parsedData).forEach(specialtyRecords => {
            specialtyRecords.forEach(record => {
              sumCompleted += record.completed;
              sumArchived += record.archived;
              sumTotal += record.total;
            });
          });
          setTotals({
            completed: sumCompleted,
            archived: sumArchived,
            total: sumTotal
          })
          showCasesBySpecialtyPeriod.open() 
        }
        
        
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getTotalFees = (initialDateUrl, endDateUrl) => {
    showTotalFees.close()
    // jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/cases-quarterly-payments?start=${initialDateUrl}&end=${endDateUrl}`)
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/cases-payments-group-by?start=${initialDateUrl}&end=${endDateUrl}&groupBy=${periodFee.value}`)
      .then(res => {
        setTotalFees(res)
        setPaymentDonutChartData(proccessPaymentsGroupByCategory(res?.categoryPercentages))
        showTotalFees.open()
      })
      .catch(err => {
        
      })
  }

  const getCasesVolume = (initialDateUrl, endDateUrl) => {
    showVolumeOfCases.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/cases-summary-group-by?start=${initialDateUrl}&end=${endDateUrl}&groupBy=${periodVolume.value}`)
      .then(res => {
        const processedVolumeData = proccessCaseVolume(res, periodVolume.value, useFiscalYear)
        setVolumeOfCases(processedVolumeData)
        const sumCompleted = processedVolumeData.reduce((acc, curr) => acc + curr.completed, 0);
        const sumArchived = processedVolumeData.reduce((acc, curr) => acc + curr.archived, 0);
        const sumTotal = processedVolumeData.reduce((acc, curr) => acc + curr.total, 0);
        setTotalsVolume({
          completed: sumCompleted,
          archived: sumArchived,
          total: sumTotal
        })
        showVolumeOfCases.open()
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {

    // save to local storage
    localStorage.setItem('selectedPeriod', selectedPeriod);

    if(selectedPeriod === 'Custom') {
      // periodSelection.toggle()
      var localStorageInitialDate = localStorage.getItem('initialDate')
      var localStorageEndDate= localStorage.getItem('endDate')
      
      // Ensure localStorage values exist before attempting to split and construct dates
      if (localStorageInitialDate && localStorageEndDate) {
          setInitialDate(new Date(
              parseInt(localStorageInitialDate.split('-')[0], 10), // year
              parseInt(localStorageInitialDate.split('-')[1], 10) - 1, // month, adjusted for zero-index
              parseInt(localStorageInitialDate.split('-')[2], 10) // day
          ));

          setEndDate(new Date(
              parseInt(localStorageEndDate.split('-')[0], 10), // year
              parseInt(localStorageEndDate.split('-')[1], 10) - 1, // month, adjusted for zero-index
              parseInt(localStorageEndDate.split('-')[2], 10) // day
          ));
      }
      return
    }

    if(selectedPeriod === 'Last 7 days') {
      setInitialDate(getDate(-7))
      setEndDate(new Date())
    }

    if(selectedPeriod === 'Last 4 weeks') {
      setInitialDate(getDate(-28))
      setEndDate(new Date())
    }

    if (selectedPeriod === 'Last 3 months') {
      setInitialDate(getDate(-90)); // Approximation, consider using a more accurate method
      setEndDate(new Date());
    }
    
    if (selectedPeriod === 'Last 12 months') {
      setInitialDate(getDate(-365)); // Approximation, consider using a more accurate method
      setEndDate(new Date());
    }
    
    if (selectedPeriod === 'Month to date') {
      const today = new Date();
      setInitialDate(new Date(today.getFullYear(), today.getMonth(), 1));
      setEndDate(today);
    }
    
    if (selectedPeriod === 'Quarter to date') {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const month = today.getMonth();
      const quarterStartMonth = month - (month % 3);
      setInitialDate(new Date(today.getFullYear(), quarterStartMonth, 1));
      setEndDate(today);
    }
    
    if (selectedPeriod === 'Year to date') {
      const today = new Date();
      setInitialDate(new Date(today.getFullYear(), 0, 1)); // Start of the current year
      setEndDate(today);
    }
    
    if (selectedPeriod === 'All time') {
      // Assuming 'All time' means from a very early date till now
      // You might want to adjust this based on what 'All time' means for your application
      setInitialDate(new Date(1970, 0, 1)); // Set to Unix epoch start, adjust as needed
      setEndDate(new Date());
    }

  }, [selectedPeriod])

  useEffect(() => {

    function capitalizeFirstLetter(string) {
      const lowerCase = string.toLowerCase().trim();
      
      const words = lowerCase.split(' ');
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    if(!initialDate && !endDate) {
      return
    }
    
    const initialDateUrl = initialDate
      ? `${initialDate.getFullYear()}-${(initialDate.getMonth() + 1).toString().padStart(2, '0')}-${initialDate.getDate().toString().padStart(2, '0')}`
      : '';
        
    const endDateUrl = endDate 
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      : '';

    localStorage.setItem('initialDate', initialDateUrl);
    localStorage.setItem('endDate', endDateUrl);
  
    showMap.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/count-by-state?start=${initialDateUrl}&end=${endDateUrl}`)
      .then(res => {
        let sanitizedData = res.filter(obj => obj[0] !== null).map(obj => [obj[0].trim(), obj[1]])
        // let usCasesData = sanitizedData
        // check if sanitizedData containt at least one state matching us state name or abbreviation
        if (sanitizedData.some(obj => UsStatesName.includes(capitalizeFirstLetter(obj[0])) || UsStatesAbbr.includes(obj[0].toUpperCase()))) {
          let usCasesData = sanitizedData.filter(obj => UsStatesName.includes(capitalizeFirstLetter(obj[0])) || UsStatesAbbr.includes(obj[0].toUpperCase()))
          let excludedData = sanitizedData.filter(obj => !UsStatesName.includes(capitalizeFirstLetter(obj[0])) && !UsStatesAbbr.includes(obj[0].toUpperCase()))
          // get cases from abreviation and put them to full name. If both are present, sum into one with full name
          let usConsolidatedData =
            usCasesData.map(obj => {
              if (UsStatesAbbr.includes(obj[0].toUpperCase())) {
                return [UsStatesName[UsStatesAbbr.indexOf(obj[0].toUpperCase())], obj[1]]
              } else if (UsStatesName.includes(capitalizeFirstLetter(obj[0]))) {
                return [capitalizeFirstLetter(obj[0]), obj[1]]
              }
            })
          let usConsolidatedData2 = usConsolidatedData.reduce((acc, curr) => {
            // Find if the current item's key exists in the accumulator
            const existingObjIndex = acc.findIndex(obj => obj[0] === curr[0]);
            if (existingObjIndex !== -1) {
              // If exists, sum their values
              acc[existingObjIndex] = [acc[existingObjIndex][0], acc[existingObjIndex][1] + curr[1]];
            } else {
              // If not, add the current item to the accumulator
              acc.push(curr);
            }
            return acc;
          }, []);
          // change full name by abbreviation
          let usConsolidatedData3 = usConsolidatedData2.map(obj => {
            if (UsStatesName.includes(capitalizeFirstLetter(obj[0]))) {
              return [UsStatesAbbr[UsStatesName.indexOf(capitalizeFirstLetter(obj[0]))], obj[1]]
            } else if (UsStatesAbbr.includes(obj[0].toUpperCase())) {
              return [obj[0], obj[1]]
            }
          })

          // add abreviation at the end
          let usConsolidatedData4 = usConsolidatedData3.map(obj => {
            if(UsStatesAbbr.includes(obj[0].toUpperCase())){
              return [obj[0].toUpperCase(), obj[1], UsStatesName[UsStatesAbbr.indexOf(obj[0].toUpperCase())]]
            }
            
            return [obj[0].toUpperCase(), obj[1], 'US']
          })
          setGeoBarChartData(useFullName 
              ? sanitizedData // only remove nulls
              // ? usConsolidatedData2 // this have NY and New York together and summed. New York
              // : usConsolidatedData3) // this have NY and New York together and summed. NY
              : usConsolidatedData4) // this have NY and New York together and summed. NY and New York at the end
          setDataMap(usConsolidatedData4) // this have NY and New York together and summed. NY and New York at the end
          // setDataMap(sanitizedData) // having too many requests outside of US can distort data on map
          showMap.open()    
        }else{
          setGeoBarChartData(sanitizedData)
          setDataMap(null)
          // showMap.close()
          showMap.open()    
        }
      })
      .catch(err => {
        console.log(err)
      })

    showCasesBySpecialty.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/count-by-category?start=${initialDateUrl}&end=${endDateUrl}`)
      .then(res => {
        setCasesBySpecialty(res)
        showCasesBySpecialty.open()
      })
      .catch(err => {
        console.log(err)
      })

    showCategorySummary.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/case-requests/category-summary?start=${initialDateUrl}&end=${endDateUrl}`)
      .then(res => {
        // Step 1: Calculate total CASE_CLOSED across all categories
        let totalCaseClosed = Object.values(res).reduce((total, category) => {
          return total + (category.CASE_CLOSED || 0);
        }, 0);

        // Step 2: Iterate through each category
        const results = Object.entries(res).map(([categoryName, categoryValues]) => {
          const count = categoryValues.CASE_CLOSED || 0;
          const percentage = (count / totalCaseClosed) * 100;

          // Step 3: Construct result object for each category
          return {
            categoryName,
            count,
            percentage: percentage.toFixed(2) // rounded to 2 decimal places for readability
          };
        });

        setCategorySummary(results.filter(result => result.count > 0))
        showCategorySummary.open()
      })
      .catch(err => {
        console.log(err)
      })

    caseRequestsByspecialtyGroups(initialDateUrl, endDateUrl)
    getTotalFees(initialDateUrl, endDateUrl)
    getCasesVolume(initialDateUrl, endDateUrl)

    showRatings.close()
    jwtGetFetcher(getJwt())(`/api/admin/stats/feedbacks/count-by-type?startDate=${initialDateUrl}&endDate=${endDateUrl}`)
      .then(res => {
        setRatings(res)
        showRatings.open()
      })
      .catch(err => {
        console.log(err)
      })
    
  }, [initialDate, endDate])


  // Get Cases Requests by Specialty Groups upon changing option Group by
  useEffect(() => {
    if(!initialDate && !endDate) {
      return
    }

    const initialDateUrl = initialDate
      ? `${initialDate.getFullYear()}-${(initialDate.getMonth() + 1).toString().padStart(2, '0')}-${initialDate.getDate().toString().padStart(2, '0')}`
      : '';
        
    const endDateUrl = endDate 
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      : '';

    caseRequestsByspecialtyGroups(initialDateUrl, endDateUrl)
  }, [period])

  // Get total fees upon changing option Group by 
  useEffect(() => {
    if(!initialDate && !endDate) {
      return
    }

    const initialDateUrl = initialDate
      ? `${initialDate.getFullYear()}-${(initialDate.getMonth() + 1).toString().padStart(2, '0')}-${initialDate.getDate().toString().padStart(2, '0')}`
      : '';
        
    const endDateUrl = endDate 
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      : '';

    getTotalFees(initialDateUrl, endDateUrl)
  }, [periodFee])

  // Get cases volume upon changing option Group by 
  useEffect(() => {

    if(!initialDate && !endDate) {
      return
    }

    const initialDateUrl = initialDate
      ? `${initialDate.getFullYear()}-${(initialDate.getMonth() + 1).toString().padStart(2, '0')}-${initialDate.getDate().toString().padStart(2, '0')}`
      : '';
        
    const endDateUrl = endDate 
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      : '';

    getCasesVolume(initialDateUrl, endDateUrl)

  }, [periodVolume])

  // ------------------------------------------------------------------

  return <>
    <PageContainer css="max-width: 100%;">
        <WithIsSticky>{(sentinel, isSticky) => <>
        {sentinel("margin-top:-2.5rem;")}
        <AnalyticsHeader isSticky={isSticky}>
        <div>
          <SectionTitle>Analytics</SectionTitle>
          <p>Choose a timeframe to filter this report</p>
        </div>
        <DateSelector>
          <PresetsButton onClick={periodSelection.toggle}>
            <span>{selectedPeriod}</span><Icon icon="arrow-down"/>
          {periodSelection.isOpen &&
            <ContextMenu fill>
              {periods.map(p =>
                <Li icon={selectedPeriod === p && 'check'} label={p} key={p}
                    onClick={(e) => {
                      if(p == 'Custom'){
                        datePicker.open(); 
                        e.preventDefault();
                      }
                      setSelectedPeriod(p)
                    }}/>
              )}
            </ContextMenu>}
          </PresetsButton>
          <DateButton onClick={(e) => {datePicker.toggle(); e.preventDefault();}}> 
            <Icon icon="calendar" /> {initialDate ? initialDate.toLocaleDateString('en-US', options).replace(`, ${currentYear}`, '') : ''} - {endDate ? endDate.toLocaleDateString('en-US', options).replace(`, ${currentYear}`, '') : ''}
            {datePicker.isOpen &&
              <AdvancedSearchPopup
                focusedInput={datePicker.isOpen}
                closePopup={datePicker.close}
                initialDate={initialDate}
                setInitialDate={setInitialDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSelectedPeriod={setSelectedPeriod}
              />
            }
          </DateButton>
        </DateSelector>
      </AnalyticsHeader>
      </>}</WithIsSticky>
      
      {showMap.isOpen 
        ? <Card>
          <div style={{display: "flex", flexDirection: "row", marginTop: "2rem", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap"}}>
            <SectionTitle style={{flex: 1}}>Origin of Case Requests</SectionTitle>
            <SectionTitle style={{flex: 1}}>Case Origination by State</SectionTitle> 
          </div>
          <div style={{display: "flex", flexDirection: "row", marginTop: "2rem"}}>
            {dataMap != null && <div style={{width: "50%"}}>
              <MapCasesByState data={dataMap} hoveredState={hoveredState} setHoveredState={setHoveredState}/>
            </div>}
            <div style={{width: "50%"}}>
              {!geoBarChartData || geoBarChartData.length == 0 
                ?  <NotEnoughData>Not enough data</NotEnoughData>
                : <BarChartCasesByState data={geoBarChartData} useFullName={useFullName} maxLimit={25}
                  hoveredState={hoveredState} setHoveredState={setHoveredState}
                />
              }
            </div>
          </div>
        </Card> 
        : <Card>
          <Loading size={40} style={{margin: "4rem auto"}} />
        </Card>
      }

      {(showCasesBySpecialty.isOpen && showCategorySummary.isOpen) 
        ? <Card>
          <SectionTitle>Case Requests by Division</SectionTitle>
          <p>Division data is aggregated from tags</p>
          <p><i>Note: The division charts intend to reflect data per division tag. Thus, the total cases by division will not equal actual totals when multiple division tags are attributed to the same case.</i></p>
          <div style={{display: "flex", flexDirection: "row", marginTop: "2rem", marginBottom: "4rem", gap: "1rem"}}>
            <div style={{width: "50%"}}>
              <DonutChart data={casesBySpecialty} title={"Case Requests Total"}/>
            </div>
            <div style={{width: "50%"}}>
              <DonutChart data={categorySummary} title={"Completed Cases"}/>
            </div>
          </div>
          
          {showCasesBySpecialtyPeriod.isOpen 
            ? <div>

              <div className="user-dropdown" onClick={contextMenuPeriod.open}></div>
              <SectionTitle>Case Requests by Division by <PeriodicitySeletor onClick={contextMenuPeriod.open}>
                <span className='label'>{period?.label}</span><Icon icon="arrow-down"/>
                {contextMenuPeriod.isOpen &&
                  <ContextMenu >
                    {periodsGroup.map(p =>
                      <Li icon={period?.value === p.value && 'check'} label={p.label} key={p.value}
                          onClick={() => setPeriod(p)}/>
                    )}
                  </ContextMenu>
                } </PeriodicitySeletor>
              </SectionTitle>
              <p><i>Note: The division charts intend to reflect data per division tag. Thus, the total cases by division will not equal actual totals when multiple division tags are attributed to the same case.</i></p>
              <CountBadgesContainer>
                {totals.completed != null && <CountBadges 
                  background="#6695b1"
                  color="white">{totals.completed} Completed cases</CountBadges>}
                {totals.archived != null && <CountBadges 
                  background="#93cfe7"
                  color="black">{totals.archived} Archived cases</CountBadges>}
                {totals.total != null && <CountBadges background="#f6f6f6" color="black">{totals.total} Total cases</CountBadges>}
              </CountBadgesContainer>
              <ChartsGrid>
                {
                  casesBySpecialtyPeriod && Object.entries(casesBySpecialtyPeriod).map(([key, value]) => {
                    const periodsCount = count(value)
                    return <>
                      {periodsCount > 1 
                        ? periodsCount > 12 && period.value == "QUARTER"
                          ? <AreaChartMultiple specialty={key} data={value.slice(-12)} groupBy={period.value}/>
                          : periodsCount > 12 && period.value == "MONTH"
                            ? <AreaChartMultiple specialty={key} data={value} groupBy={period.value}/>
                            : periodsCount > 10 && period.value == "YEAR"
                              ? <AreaChartMultiple specialty={key} data={value.slice(-10)} groupBy={period.value}/>
                              : <AreaChartMultiple specialty={key} data={value} groupBy={period.value}/>
                        : <div>
                            <p style={{margin: 'auto', textAlign: 'center'}}><b>{key}</b></p>
                            <NotEnoughData>Not enough data</NotEnoughData>
                        </div>
                      }
                    </>
                  })
                }  
              </ChartsGrid>
            </div>
            : <Loading size={40} style={{margin: "4rem auto"}} />
          }

        </Card> 
        : <Card>
          <Loading size={40} style={{margin: "4rem auto"}} />
        </Card>
      }

      {showVolumeOfCases.isOpen 
        ? <Card>
           <SectionTitle>Volume of Patient Case Requests by <PeriodicitySeletor onClick={contextMenuPeriodVolume.open}>
           <span className='label'>{periodVolume?.label}</span><Icon icon="arrow-down"/>
            {contextMenuPeriodVolume.isOpen &&
              <ContextMenu >
                {periodsGroup.map(p =>
                  <Li icon={periodVolume?.value === p.value && 'check'} label={p.label} key={p.value}
                      onClick={() => setPeriodVolume(p)}/>
                )}
              </ContextMenu>
            } </PeriodicitySeletor>
          </SectionTitle>
          {!volumeOfCases || volumeOfCases?.length <= 1 
            ? <NotEnoughData>Not enough data</NotEnoughData>
            : <div style={{margin: 'auto', textAlign: 'center', paddingTop: '2rem'}}>
              <AreaChartVolume data={(volumeOfCases.length > 24 && (periodVolume.value == "QUARTER" || periodVolume.value == "YEAR")) ? volumeOfCases.slice(-24) : volumeOfCases} groupBy={periodVolume.value}/>
              <CountBadgesContainer>
                {totalsVolume.completed != null && <CountBadges 
                  background="#6695b1"
                  color="white">{totalsVolume.completed} Completed cases</CountBadges>}
                {totalsVolume.archived != null && <CountBadges 
                  background="#93cfe7"
                  color="black">{totalsVolume.archived} Archived cases</CountBadges>}
                {totalsVolume.total != null && <CountBadges background="#f6f6f6" color="black">{totalsVolume.total} Total cases</CountBadges>}
            </CountBadgesContainer>
            </div>
          }
          </Card> 
        : <Card>
          <Loading size={40} style={{margin: "4rem auto"}} />
        </Card>
      }

      {showTotalFees.isOpen
        ? <Card>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap"}}>
            <SectionTitle style={{flex: 1}}>Total Fees by <PeriodicitySeletor onClick={contextMenuPeriodFee.open}>
              <span className='label'>{periodFee?.label}</span><Icon icon="arrow-down"/>
              {contextMenuPeriodFee.isOpen &&
                <ContextMenu >
                  {periodsGroup.map(p =>
                    <Li icon={periodFee?.value === p.value && 'check'} label={p.label} key={p.value}
                        onClick={() => setPeriodFee(p)}/>
                  )}
                </ContextMenu>
              } </PeriodicitySeletor>
            </SectionTitle>
            <div style={{flex: 1}}>
              <SectionTitle>Total Fees by Division Tag</SectionTitle>
              <p><i>Note: This division chart intends to reflect the percentage of revenue created per division tag. Thus, the total revenue by division will not equal actual totals when multiple division tags are attributed to the same case.</i></p>
            </div>
          </div>
          <div style={{display: "flex", flexDirection: "row", marginTop: "2rem", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap"}}>
            <div style={{width: "50%"}}>
            
              {(() => {
                switch (periodFee.value) {
                  case "MONTH":
                    return totalFees?.monthlySummaries && totalFees?.monthlySummaries.slice(Math.max(totalFees?.monthlySummaries.length - 12, 0)).length > 1
                      ? <AreaChartFees period={periodFee.value}
                        // data={totalFees?.monthlySummaries.slice(Math.max(totalFees.monthlySummaries.length - 12, 0))}
                        data={totalFees?.monthlySummaries}
                        />
                      : <NotEnoughData>Not enough data</NotEnoughData>;
                  
                  case "QUARTER":
                    return totalFees?.quarterlySummaries && totalFees?.quarterlySummaries.slice(Math.max(totalFees?.quarterlySummaries.length - 4, 0)).length > 1
                      ? <AreaChartFees period={periodFee.value}
                        data={totalFees?.quarterlySummaries.slice(Math.max(totalFees?.quarterlySummaries.length - 8, 0))}/>
                      : <NotEnoughData>Not enough data</NotEnoughData>;

                  case "YEAR":
                    return totalFees?.yearlySummaries && totalFees?.yearlySummaries.slice(Math.max(totalFees?.yearlySummaries.length - 5, 0)).length > 1
                      ? <AreaChartFees period={periodFee.value}
                        data={totalFees?.yearlySummaries.slice(Math.max(totalFees.yearlySummaries.length - 5, 0))}/>
                      : <NotEnoughData>Not enough data</NotEnoughData>;
                  
                  default:
                    return null; // Or any default component you wish to render
                }
              })()}
            </div>
            <div style={{width: "50%"}}>
              {paymentDonutChartData && paymentDonutChartData.length >= 1 
                ? <DonutChartPayment data={paymentDonutChartData}/>
                : <NotEnoughData>Not enough data</NotEnoughData>
              }
            </div>
          </div>
        </Card>
        : <Card>
          <Loading size={40} style={{margin: "4rem auto"}} />
        </Card>
      }

      {showRatings.isOpen 
        ? <Card style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <StarsRatingDisplay 
              label={"Physician Satisfaction"}
              average={ratings && ratings.filter(r => r[0] === "Expert").map(r => r[2])} 
              countAnswers={ratings && ratings.filter(r => r[0] === "Expert").map(r => r[1])} 
            />
            <StarsRatingDisplay 
              label={"Patient Satisfaction"}
              average={ratings && ratings.filter(r => r[0] === "Patient").map(r => r[2])} 
              countAnswers={ratings && ratings.filter(r => r[0] === "Patient").map(r => r[1])} 
              // average={0} countAnswers={0} TODO chance to check 0 stars 
            />
          </Card> 
        : <Card>
          <Loading size={40} style={{margin: "4rem auto"}} />
        </Card>
      }
      
    </PageContainer>
    <PurviewFooter/>
  </>
}
