import _ from 'lodash/fp'
import React from 'react'
import {smoothScroll} from "@startlibs/utils";
import {willUseSharedCallback} from "@startlibs/core";

const toggle = (path) => ({setData}, next) => {
  setData(_.update(path, current => next === undefined ? !current : next))
}

const toggleItem = (path) => ({setData}, item) => {
  setData(_.update(path, list => list.includes(item) ? _.difference(list, [item]) : list.concat(item)))
}

const promissableToggle = (path) => ({setData}, value) => {

  let result = {}
  result.promise = new Promise((res, rej) => {
    result.res = res
    result.rej = rej
    setData(_.update(path, (promissable) => {
      if (promissable) {
        if (!value) {
          return value
        } else {
          return value(promissable.res, promissable.rej)
        }
      } else {
        return result
      }
    }))
  })
  return result
}
export const [useRegisterDropboxRef,getDropboxRefs] = willUseSharedCallback()

export const UIAction = {
  ToggleSessionOnly: toggle('sessionOnly'),
  ToggleImportStudiesDialog: toggle('dialogs.importStudies'),
  ToggleActivityLogDialog: toggle('dialogs.activityLog'),
  ToggleDeleteRecordDialog: toggle('dialogs.deleteRecord'),
  ToggleUnidentifiedFilesDialog: toggle('dialogs.unidentifiedFiles'),
  ToggleCancelUploadsDialog: promissableToggle('dialogs.cancelUploads'),
  ToggleCancelProcessingDialog: promissableToggle('dialogs.cancelProcessing'),
  ToggleSplitPDFDialog: toggle('dialogs.splitPDF'),
  ToggleImportCareQualityInstitutionsDialog: toggle('dialogs.importCareQualityInstitutions'),
  ToggleImportCareQualityRecordsDialog: toggle('dialogs.importCareQualityRecords'),
  SetCancelDialog: ({setData}, value) => { // Dialog to cancel dicom uploads. Open with the record. Set as false to close
    setData(_.set('dialogs.cancelDialog', value))
  },
  SetDicomDetailsDialog: ({setData}, value) => { // Dialog to show details of uploading dicom. Open with the record. Set as false to close
    setData(_.set('dialogs.dicomDetailsDialog', value))
  },

  // Canceled records during processing
  SetCanceledDicomUIDs: ({setData}, value) => {
    setData(_.set('canceledDicomUIDs', value))
  },

  ScrollToDropFilesBox: () => {
    const ref = _.find(_.get('current'),getDropboxRefs())
    smoothScroll(ref.current)
  },

  SetSelectedCarequalityInstitutions: ({setData}, value) => {
    setData(_.set('selectedCarequalityInstitutions', value))
  },

  SetSelectedCarequalityInstitutionsWithPatients: ({setData}, value) => {
    setData(_.set('selectedCarequalityInstitutionsWithPatients', value))
  },
  
  SetSelectMode: ({setData}, value) => {
    setData(_.set('selectMode', value))
  },
  SetSelectedRecords: ({setData}, value) => {
    setData(_.set('selectedRecords', value))
  },
  ToggleSelectedRecord: toggleItem('selectedRecords'),
  ToggleCollapsed: toggleItem('collapsed')
}
