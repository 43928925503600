import { SplitColumnsContainer } from '@startlibs/components'
import { Checkbox, Combobox, FormValue, RadioboxGroup, SimpleCheckbox, TextInput, ToggleCheckbox, useFormValue } from '@startlibs/form'
import { getColor } from '@startlibs/utils';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Card, SectionHeading } from '../../components/PageLayout';
import { REF_PHYSICIAN } from '../../enums/ContactRelationType'
import { ConfirmEmailInput } from '../../components/ConfirmEmailInput'
import { CountryRegionData } from 'react-country-region-selector';
import { StyledCountryDropdown, StyledRegionDropdown, StyledRegionsDiv } from './PatientDetails';
import { getFormattedStates } from '../PatientAndContactDetails';
import { ComboboxContainer } from './ContactDetails';

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const VIEWPORT_MIN_WIDTH = 500

export const ReferringPhysician = ({ readOnly, isAdmin, form, countryList, showSelects }) => {

  const [relationType] = useFormValue('contactDetails.contactRelationType')
  
  if (relationType === REF_PHYSICIAN) {
    return null
  }

  return <>
    <SectionHeading>
      <h3>Referring physician</h3>
      <p>
        This physician can be requested to take action on this case and may
        receive a copy of any resulting reports.
      </p>
    </SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">

        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.fullName.first"
          label="First name"
          placeholder="Ex: John"
          maxLength={255}
        />

        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.fullName.last"
          label="Last name"
          placeholder="Ex: Doe"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.institution"
          label="Institution"
          placeholder="Ex: Purview"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        {showSelects ? <>
          <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
            disabled={readOnly}
            className="combobox fs-exclude"
            placeholder="Please select country"
            label={"Country"}
            path='referringPhysician.address.country'
            options={countryList}
            onChange={(val) => {
              var states = getFormattedStates(val)
              if (states.length > 0) {
                if (states.findIndex(x => x === form.getValue('referringPhysician.address.state')) == -1) {
                  form.setValue('referringPhysician.address.state', null)
                }
              }
            }}
          /></ComboboxContainer>
          <FormValue path="referringPhysician.address.country">{country =>
            <ComboboxContainer readOnly={readOnly} className='fs-exclude'>
              <Combobox
                disabled={readOnly}
                className="combobox fs-exclude"
                placeholder="Please select state"
                label={"State"}
                path='referringPhysician.address.state'
                options={getFormattedStates(country)}
              // onChange={(val) => setRegion(val)}
              />
            </ComboboxContainer>
          }</FormValue>
        </> : <>
          <TextInput
            disabled={readOnly}
            type="text"
            path="referringPhysician.address.country"
            label="Country"
            placeholder="Ex: USA"
            maxLength={255}
          // mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="referringPhysician.address.state"
            label="State"
            placeholder="Ex: New York"
            maxLength={255}
          // mandatory
          />
        </>}
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.address.city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
        // mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.address.zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
        // mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.address.address"
          label="Street address"
          placeholder="Ex: 001 Street Name"
          maxLength={255}
        // mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.email"
          label="Email address"
          placeholder="Ex: physicians@email.com"
          maxLength={255}
        />
        <ConfirmEmailInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.confirmEmail"
          confirmingPath="referringPhysician.email"
          label="Confirm email"
          placeholder="Ex: physicians@email.com"
          maxLength={255}
          mandatory={false}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          path="referringPhysician.phoneNumber"
          label="Phone number"
          setValue={(newValue, _, setValue) => setValue(newValue.replace(/[^0-9()\-+\s]/, ''))}
          // helpText="(include country code)"
          placeholder="Ex: +1 (123) 456 7890"
          maxLength={255}
          bellowDescText="(include country code)"
        />
        <TextInput
          disabled={readOnly}
          path="referringPhysician.faxNumber"
          label="Fax number"
          setValue={(newValue, _, setValue) => setValue(newValue.replace(/[^0-9()\-+\s]/, ''))}
          // helpText="(include country code)"
          placeholder="Ex: +1 (123) 456 7890"
          maxLength={255}
          bellowDescText="(include country code)"
        />
        {isAdmin && <TextInput
          disabled={readOnly}
          type="text"
          path="referringPhysician.npi"
          label="NPI"
          placeholder="Ex: 1234567890"
          maxLength={255}
        // mandatory
        />}
      </SplitColumnsContainer>
      <FormValue path="referringPhysician.acceptContact">{acceptContact =>
        acceptContact !== null && <>
          {isAdmin && <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <SimpleCheckbox
              disabled={readOnly}
              path="referringPhysician.acceptContact"
              label={<span css="font-size:13px;">This physician has been made aware of this case, and can be contacted as necessary</span>}
            />
          </SplitColumnsContainer>}
        </>}
      </FormValue>
    </Card>
  </>
}
