import { Icon, Li, ListHeading } from '@startlibs/components';
import {Routes, Route, useLocation, useNavigate} from 'react-router'
import { useToggle } from '@startlibs/core';
import React, { useState } from 'react'
import {AdminControlPanel} from './AdminControlPanel'
import {AdminCreateRequest} from './AdminCreateRequest'
import {AdminDashboard} from './AdminDashboard'
import {AdminExpertsRoutes} from './AdminExpertsRoutes'
import {AdminRequest} from './AdminRequest'
import {DIVISION, OPERATOR, SUPER} from '../enums/UserRole';
import {InfoBox} from '../components/InfoBox'
import {NavbarTabs, UserNavbar} from '../components/Navbar';
import {PageLoadingSuspense} from '../components/PageLoading'
import {ProviderTitle} from '../components/ProviderTitle'
import {SuspensePage} from '../components/SuspensePage'
import {lazyProviderInfo, lazyUserInfo} from '../components/WithProvider'
import {setJwt} from '../hooks/useJwt';
import {AdminLogin} from './AdminLogin'
import { useIntl } from 'react-intl';
import { AdminSSOLogin } from './AdminSSOLogin';
import {Redirect} from "../components/Redirect";
import { preventDefault } from '@startlibs/utils';
import { confirmExitPage } from '../hooks/useConfirmExitPage';
import { DicomRouterProvider } from '../utils/DicomRouterContext';

// const DEV_JWT = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQURNSU4iLCJyb2xlIjoiU1VQRVIiLCJwcm92aWRlcklkIjoiMSIsImxvZ2luIjoiYWRtaW5AbG9jYWxob3N0In0.yDpjz6OE35ssRYj3A77JFvk7YoPH67Xmr1LdWBEetbw'

// Use this with legal dev env
// const DEV_JWT = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQURNSU4iLCJyb2xlIjoiU1VQRVIiLCJwcm92aWRlcklkIjoiMSIsImxvZ2luIjoiZ21vbnRlaXJvQHB1cnZpZXcubmV0In0.GEPnQzNDGatLp_hwQMlQjtvXX8K0obnvMD6GjF4H-jjAI8sgWw5AoLYWe4-nOXRgpROSxXL6WUZfPidfCNp2sHQZ0sxQWDABy1WbNBfGoNvaUFNQTFImuf3GEDFQegyNiEAD9sMIkJEgAa6g-Qj-UFwIDdCDKVx0ZMj-zhlw8FIXNMNk5mrJ4ctpuGP-lIpUViUzKHrnVHiJMrSjGO66jgNwoTgOZgecujw9UnWmOqbf0qCH3Usz7R0_OSxmQWHlI5R_60FMy46VeMutxvv0bznN1VvED910LZTl28kQW33NdxPXdg8z7VMlxcAMO-2f6Bgz1daqZFwiH1x_4O3mKQ'
const DEV_JWT = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQURNSU4iLCJyb2xlIjoiU1VQRVIiLCJwcm92aWRlcklkIjoiMSIsImxvZ2luIjoiZ21vbnRlaXJvQHB1cnZpZXcubmV0In0.U6XSJf8qaPdQGRpeitE-_d62ROz5lDXQAXib5HqkUWA'
// const DEV_JWT_SEC = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiQURNSU4iLCJyb2xlIjoiU1VQRVIiLCJwcm92aWRlcklkIjoiMiIsImxvZ2luIjoiZ21vbnRlaXJvQHB1cnZpZXcubmV0IiwiZXhwIjoxNjQ2MzA5MDE1LCJpYXQiOjE2NDYyMjI2MTV9.z7P6_LasGBBLnwiZl0mYiUsjjF0du4mbXuOFqUbz12Q'
const getJwt = () => window.JWT_URL_PARAM || window.localStorage.getItem("admin-jwt") || DEV_JWT // Comment/Uncomment this to automatic login admin


export const AdminRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [divisionsFilter, setDivisionsFilter] = useState()
  
  const providerInfo = lazyProviderInfo.read()

  if (getJwt()) {
    setJwt(getJwt(),"admin")
  }

  const toggle = useToggle(true)
  const intl = useIntl()

  if (location.pathname === "/admin/login") {
    return <AdminLogin />
  }

  if ((location.pathname === "/admin/sso/login") && (providerInfo?.ssoEnabled === true)){
    return <AdminSSOLogin/>
  }
  
  if (!getJwt()) {
    return providerInfo?.ssoEnabled === true
      ?
      <Redirect to="/admin/sso/login" />
      :
      <Redirect to="/admin/login" />
  }

  const userInfo = lazyUserInfo.read()
  

  // If a page has useSetConfirmExitPage and we add this confrimation on redirects, then it will be asked to confirm exit
  const confirmRedirect = preventDefault((e) => {
    // const href = e.target.href // Not work in this new
    // const href = e.target.getAttribute('href') // Works
    const href = e.target.pathname // Works and already used in other part of system
    confirmExitPage().then(() => navigate(href))
  })

  return <>
    {
      toggle.isOpen && userInfo.role === DIVISION && !userInfo.categories?.length &&
      <InfoBox noDivisionsNotice lightYellow flex>
        You are no longer tied to any division. <br/>
        Please contact a super administrator.
        <Icon icon="x" onClick={toggle.close} />
      </InfoBox>
    }
    <UserNavbar
      indexUrl="/admin"
      resetPasswordUrl="/api/admin/resetPassword"
      subtitle="Administrator"
      exitUrl='/api/adminAuthUrl'
      routes={<>
        <NavbarTabs onClick={confirmRedirect} selected={location.pathname === "/admin" || location.pathname === "/admin/"} to="/admin">Cases</NavbarTabs>
        <NavbarTabs onClick={confirmRedirect} selected={location.pathname === "/admin/experts" || location.pathname === "/admin/experts/"} to="/admin/experts">Experts</NavbarTabs>
      </>}
      extraContextMenuItem={(userInfo) => userInfo.role !== OPERATOR && [
        <ListHeading><span>Institution settings</span></ListHeading>,
        <Li label={intl.formatMessage({defaultMessage:"Staff management", description:"Staff management label"})} icon="users-line" key="staff" linkTo="/admin/control/staff"/>,
        <Li label="Division settings" icon="divisions" key="divisions" linkTo="/admin/control/divisions"/>,
        userInfo.role === SUPER && <Li label="Notification settings" icon="email-line" key="notifications" linkTo="/admin/control/notifications"/>,
        userInfo.role === SUPER && <Li label="Data export" icon="csv" key="export" linkTo="/admin/control/export"/>,
        userInfo.role === SUPER && <Li label="Analytics" icon="analytics" key="analytics" linkTo="/admin/control/analytics"/>,
        userInfo.role === SUPER && <Li label="Accepted jurisdictions" icon="jurisdictions" key="jurisdictions" linkTo="/admin/control/jurisdictions"/>,
        userInfo.role === SUPER && <Li label="Payment fees" icon="payment" key="paymentfees" linkTo="/admin/control/paymentfees"/>,
        userInfo.role === SUPER && <Li label="Audit log" icon="audit" key="audit" linkTo="/admin/control/audit"/>,
        <ListHeading><span>Your profile</span></ListHeading>
      ].filter(Boolean)}
    />
    <ProviderTitle/>
    <PageLoadingSuspense>
      <DicomRouterProvider>
        <Routes>
          <Route path="create" element={<SuspensePage Component={AdminCreateRequest} />}/>
          <Route path="request/:requestCode/*" element={<SuspensePage Component={AdminRequest}/>}/>
          <Route path="/experts/*" element={<AdminExpertsRoutes/>}/>
          {userInfo.role !== OPERATOR && <Route path="/control/*" element={<AdminControlPanel />}/>}
          <Route path="*" element={<SuspensePage Component={AdminDashboard} default divisionsFilter={divisionsFilter} setDivisionsFilter={setDivisionsFilter}/>}/>
        </Routes>      
      </DicomRouterProvider>
    </PageLoadingSuspense>
  </>
}
