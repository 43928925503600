import {Button, ContextMenu, Li, Loading} from '@startlibs/components';
import {getColor} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CaseRequestCard, RequestCard} from '../CaseRequestCard'
import {EmptyListPlaceholder} from '../../components/StyledTable';
import {FILTER_LABEL_MAP} from '../../enums/CaseFilter';
import {AdminCaseCardMenu} from './AdminCaseCardMenu'
import {usePopupToggle} from '@startlibs/core'
import {DATE, NAME} from '../../enums/CaseOrderBy'
import {AdminCaseCardCategories} from './AdminCaseCardCategories'
import {useEditCategories} from '../experts/hooks/useEditCategories'
import {lazyUserInfo} from '../../components/WithProvider'
import {useToggleAsPriority} from '../hooks/useMarkAsPriority'


export const ResultsHeader = styled.div`
  padding-top: 2rem;
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 1rem 1rem 0;
    display: inline-block;
  }
`

export const ResultsWrapper = styled.div`
  background: ${getColor('gray240')};
  border-radius: 10px;
  padding: 0.75rem;
  min-height: 25rem;
  .cards-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  ${RequestCard} {
    flex-basis: 19.5%;
    flex-grow: 1;
    min-width: 240px;
    max-width: 490px;
    margin: 0.25rem;
  }
  ${Loading} {
    margin: 2rem auto;
  }
  ${props => props.useGrid && `
    @supports (display: grid) {
      padding: 1rem;
      .cards-wrapper {
        grid-template-columns: repeat( auto-fit, minmax(260px, 1fr) );
        display: grid;
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
        align-items: stretch;
        ${RequestCard} {
          margin: 0;
          max-width: none;
        }
      }
    }
  `}
`

export const ORDER_BY_LABEL = {
  [DATE]:"date created",
  [NAME]:"patient name",
}

export const AdminResults = ({params, setParams, refreshList, list, setList, isFiltering, isLoading}) => {
  const contextMenu = usePopupToggle()

  const userInfo = lazyUserInfo.read()

  const editCategories = useEditCategories(userInfo, (caseRequest) => (categories) =>
    setList(_.map(item => item === caseRequest ? _.set('categories',categories,item) : item))
  )

  const toggleAsPriority = useToggleAsPriority(refreshList)

  return <>
    <ResultsHeader>
      <h3>{params.state ? FILTER_LABEL_MAP[params.state] : "Active"} cases</h3> <Button.Link
      small
      icon="plus-circle"
      to="create"
    >Create new</Button.Link>
  <Button outline small withDropdown onClick={contextMenu.open} css="float:right">
        Sorting by {ORDER_BY_LABEL[params.orderBy] || "last updated date"}
        {
          contextMenu.isOpen &&
          <ContextMenu>
            <Li label="Date created" icon={params.orderBy === DATE ? "check" : undefined} onClick={() => setParams(_.set('orderBy',DATE))}/>
            <Li label="Last updated date" icon={!params.orderBy ? "check" : undefined} onClick={() => setParams(_.unset('orderBy'))}/>
            <Li label="Patient name" icon={params.orderBy === NAME ? "check" : undefined} onClick={() => setParams(_.set('orderBy',NAME))}/>
          </ContextMenu>
        }
      </Button>
    </ResultsHeader>
    {list.length > 0 || isLoading ?
      <ResultsWrapper useGrid={list.length > 2}>
        {isLoading ? <Loading
            size="36"
            borderWidth="5"
          />
          : <div className="cards-wrapper">
            {list.map(caseRequest => <CaseRequestCard
              key={caseRequest.id}
              refreshList={refreshList}
              caseRequest={caseRequest}
              toggleAsPriority={toggleAsPriority.willOpenWith}
              isResults
              linkTo={`/admin/request/${caseRequest.requestCode}`}
              contextMenu={(isOpen, toggle) => <AdminCaseCardMenu
                isOpen={isOpen}
                toggle={toggle}
                toggleAsPriority={toggleAsPriority.willOpenWith}
                caseRequest={caseRequest}
                editCategories={editCategories.willOpenWith}
                refreshList={refreshList}
              />}
              showFooter={FILTER_LABEL_MAP[params.state] === 'All'}
              categories={<AdminCaseCardCategories categories={caseRequest.categories} editCategories={editCategories.willOpenWith(caseRequest)} userInfo={userInfo}  />}
            />)}
          </div>
        }
      </ResultsWrapper>
      : <EmptyListPlaceholder
        css="margin-top: 0;"
        isFiltering={isFiltering}
      >
        <span>Currently there are no {FILTER_LABEL_MAP[params.state] !== 'All' && FILTER_LABEL_MAP[params.state].toLowerCase()} cases{isFiltering && ' matching this query'}.</span>
      </EmptyListPlaceholder>
    }
  </>
}
