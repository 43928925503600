import {Button, Icon, Loading, Tooltip} from '@startlibs/components'
import {Errors, WithForm} from '@startlibs/form'
import {useNavigate} from 'react-router'
import {useToggle} from '@startlibs/core'
import {will} from '@startlibs/utils'
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {Answer, ExpertAdditionalInfo} from '../request/forms/Answer'
import {CASE_CLOSED, CASE_REVIEWED} from '../enums/CaseState'
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout'
import {Header} from '../components/Header'
import {InfoBox, SuccessBox} from '../components/InfoBox'
import {PurviewFooter} from '../components/PurviewFooter'
import {QuestionList} from '../components/QuestionList'
import {getJwt} from '../hooks/useJwt'
import {hasAnsweredAll} from '../request/utils'
import {jwtPostFetcher} from '../utils/authFetch'
import {useConfirmExit} from '../admin/steps/hooks/useConfirmExit'
// import {useExitSave} from '../admin/steps/hooks/useExitSave'
import {getColor} from '@startlibs/utils'
import {ExpertSignatureManagement} from './ExpertSignatureManagement'
import { FeedbackDialog } from './dialogs/FeedbackDialog'
import { IfLocale } from '../hocs/IfLocale'
import { setNotification } from '../components/Notifications'
import { isRichText, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly } from '../utils/utils'


export const Dateinfo = ({timeSavedDate, timeNow}) => {
  
  // Calculate diff
  // ------------------------------------------------------------------------------------
  
  const diffTime = Math.abs(timeNow - timeSavedDate);
  const diffSeconds = Math.ceil(diffTime / (1000));
  const diffMinutes = Math.ceil(diffTime / (1000 * 60 ));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60 ));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  // < 1min: few seconds ago
  // > 1min < 1h: 15 minutes ago
  // > 1h < 1 day: 8 hours ago
  // > 1 day < 1 month: 27 days ago
  
  var printableInfo = 'Autosaved ';

  if(diffSeconds < 60){
      printableInfo += diffSeconds + ' seconds ago'
  }
  if(diffSeconds >=  60 && diffMinutes < 60){
      printableInfo += diffMinutes + ' minutes ago'
  }
  if(diffMinutes >= 60 && diffHours < 24){
      printableInfo += diffHours + ' hours ago'
  }
  if(diffHours >= 24 && diffDays < 30){
      printableInfo += diffDays + ' days ago'
  }
  
  // ------------------------------------------------------------------------------------
  
  return  <Tooltip whenEllipsis={false} content={
              <div css="text-align: center;">Autosaved on {timeSavedDate?.toLocaleString("en-US")}</div> 
          }>
              <span>{printableInfo}</span>
          </Tooltip>

}

const RetryButton = styled(Button)`
  color: ${getColor('alert')};
  font-weight: 600;
  margin-left: 1rem;
  margin-top: -2px;
  margin-bottom: -2px;
`

const QuestionsWrapper = styled(Card)`
  position: relative;
`

const QUESTIONS_PATH = 'caseInfo.questionsConsultant'
const SIGNATURE_PATH = 'caseInfo.signatures'
const ADDITIONAL_INFO_PATH = 'caseInfo.additionalFields'
const GET_EMPTY_ADDITIONAL_INFO = () => ({title: '', content: '', id: Date.now().toString(36) + '', createdByExpert: true})

const transform = _.flow(
  _.pick(['questionsConsultant','additionalFields','signatures']),
  _.update('additionalFields',_.filter(({createdByExpert,title,content}) => !createdByExpert || title || content))
)

export const ExpertAnswers = ({caseRequest, setCaseRequest,feedbackPopup}) => {
  const navigate = useNavigate()
  const readOnly = (caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) || caseRequest.readOnly

  const [dtAutoSaved, setDtAutoSaved] = useState()
  const [timeNow, setTimeNow] = useState()

  const AUTO_SAVE_INTERVAL = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      setCaseRequest(updateQuestionsOnCase())
      autoSave()
    }, AUTO_SAVE_INTERVAL);
  
    return () => clearInterval(interval); 
  }, [])

  const UPDATE_TIME_INTERVAL = 10000;
  useEffect(() => {
    const intervalCalc = setInterval(() => {
      var now = new Date()
      setTimeNow(now)
    }, UPDATE_TIME_INTERVAL);
  
    return () => clearInterval(intervalCalc); 
  }, [])

  const formRef = useRef()
  const updateQuestionsOnCase = () => _.flow(
    _.set(QUESTIONS_PATH, formRef.current.getValue(QUESTIONS_PATH)),
    _.set(SIGNATURE_PATH, formRef.current.getValue(SIGNATURE_PATH)),
    _.set(ADDITIONAL_INFO_PATH, formRef.current.getValue(ADDITIONAL_INFO_PATH))
  )
  const savingLoading = useToggle()
  const savingPreview = useToggle()
  const hasEmptyAnswers = useToggle(!hasAnsweredAll(caseRequest.caseInfo.questionsConsultant) && !caseRequest.report?.customReport)
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  const saveFetch = () => jwtPostFetcher(getJwt())('/api/expert/questionsAnswers?requestId=' + caseRequest.requestId, transform(formRef.current.getValue('caseInfo')), {method: 'PUT'})

  const save = () => savingLoading.wait(saveFetch().then(will(navigate, "/expert")))

  const autoSave = () => {
    
    const trySave = () => {

      if(!savingLoading.isOpen){
        savingLoading.wait(
          saveFetch()
            .then(
              () => {
                var now = new Date()
                setDtAutoSaved(now)
                setTimeNow(now)
              }
            )
            .catch(
              () => setNotification({type: "alert", msg: (close) => <>Changes could not be saved. <RetryButton small white onClick={_.flow(close,trySave)}>Retry</RetryButton></>})
            )
        )
      }

    }
    
    if (formRef && formRef.current && formRef.current.hasChanged && !readOnly) {
      trySave()
    }
  }
  
  useLayoutEffect(() => {
    return () => {
      setCaseRequest(updateQuestionsOnCase())
      autoSave()
    }
  }, [])
  
  useConfirmExit(formRef)

  const parseAdditionalFieldContent = _.update('content',  (content) => isRichText(content) ? safeMultipleNewLineToBrOnly(content) : safeMultipleNewLineToBr(content))
  const parseAnswer = _.update('answer',  (answer) => isRichText(answer) ? safeMultipleNewLineToBrOnly(answer) : safeMultipleNewLineToBr(answer))
  const parse = _.flow(
    _.update('caseInfo.additionalFields',_.map(item => item.content.includes('\n') ? parseAdditionalFieldContent(item) : item)),
    _.update('caseInfo.questionsConsultant',_.map(item => item.answer.includes('\n') ? parseAnswer(item) : item))
    
  )

  const setAndPreview = () =>
    savingPreview.wait(saveFetch()
      .then(
        _.flow(
          () => setCaseRequest(updateQuestionsOnCase()),
          will(navigate, `/expert/case/${caseRequest.requestId}/${hasEmptyAnswers.get() ? 'upload-report': 'report'}`)
        )
      ))

  const navigationButtons = (form) => readOnly
    ? <>
      <Button onClick={() => navigate(`/expert/case/${caseRequest.requestId}/overview`)}>Go to overview</Button>
      <Button highlight onClick={() => navigate(`/expert/case/${caseRequest.requestId}/report`)}>View report
      </Button>
    </>
    : <>
      {dtAutoSaved && <SuccessBox css="margin-right:1rem;">
        <Icon icon="check"/>
        <Dateinfo timeSavedDate={dtAutoSaved} timeNow={timeNow}/>
      </SuccessBox>}
      <Button isLoading={savingLoading.isOpen} onClick={save}>Save and view all cases</Button>
      {
        hasEmptyAnswers.isOpen
          ? <Button highlight isLoading={form.isLoading} type="submit">Skip and upload PDF report</Button>
          : <Button highlight isLoading={form.isLoading} type="submit">Save and advance</Button>
      }

      {/*<Button isLoading={savingPreview.isOpen} onClick={setAndPreview}>Preview</Button>
      */}
    </>


  return <WithForm
    alwaysSave
    // values={caseRequest}
    values={parse(caseRequest)}
    action={setAndPreview}
    ref={formRef}
    onChange={(_,caseRequest) => hasEmptyAnswers.openWith(!hasAnsweredAll(caseRequest.caseInfo.questionsConsultant) && !caseRequest.report?.customReport)}
  >{form => <>
    <PageContainer>
      {
         feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} requestId={caseRequest.requestId} />
      }
      <Header isExpert title="Expert review">
        {navigationButtons(form)}
      </Header>
      {
        caseRequest.report?.customReport && !readOnly &&
        <InfoBox lightBlue>
          {
          caseRequest.report?.useCustomReport
            ? 'A custom PDF was uploaded and is serving as the case report.'
            : 'Using the default report, but a custom PDF is also available.'
          }
        </InfoBox>
      }
      <SectionHeading>
        <h3>Questions & answers</h3>
      </SectionHeading>
      <QuestionsWrapper>
        {readOnly && caseCompletedWithoutReport && 
          <SuccessBox topRight>
            <Icon icon="check"/> <span>The case has been marked as completed, but no report was generated.</span>
          </SuccessBox>
        }
        {readOnly && !caseCompletedWithoutReport &&
          <SuccessBox topRight>
            <Icon icon="check"/> <span>This review has already been submitted.</span>
          </SuccessBox>
        }
        <QuestionList
          limit={1}
          path="caseInfo.questionsConsultant"
        >{(question, i) =>
          <Answer
            submitted={readOnly}
            i={i} key={question.id}
            form={form}
            path={['caseInfo', 'questionsConsultant']}
            onBlur={autoSave}
            requestId={caseRequest.requestId}
          />
        }</QuestionList>
      </QuestionsWrapper>
      <IfLocale not contains="LEGAL">
        <SectionHeading>
          <h3>Additional information</h3>
        </SectionHeading>
        <QuestionsWrapper>
          <QuestionList
            disabled={readOnly}
            path="caseInfo.additionalFields"
            addLabel="Add a new field"
            getEmpty={GET_EMPTY_ADDITIONAL_INFO}
          >{(question, i) =>
            question.createdByExpert
            ? <ExpertAdditionalInfo
                onBlur={autoSave}
                submitted={readOnly}
                i={i} key={question.id}
                form={form}
                path={['caseInfo', 'additionalFields']}
                requestId={caseRequest.requestId}
              />
            : <Answer
                submitted={readOnly}
                i={i}
                key={question.id}
                form={form}
                path={['caseInfo', 'additionalFields']}
                questionNumber={null}
                onBlur={autoSave}
                questionKey='title'
                answerKey='content'
                requestId={caseRequest.requestId}
              />
          }</QuestionList>
        </QuestionsWrapper>
      </IfLocale>
      <SectionHeading>
        <h3>Signatures</h3>
        <p>To sign your report, please add a signature</p>
      </SectionHeading>
      <Card css="position:relative;">
        <React.Suspense fallback={<Loading absolute />}>
          <ExpertSignatureManagement path={['caseInfo','signatures']} disabled={readOnly} caseRequest={caseRequest} form={form}/>
        </React.Suspense>
      </Card>
      <Errors/>
      <PageFooter>
        {
          !readOnly &&
          <div className="left-wrapper">
            <Button onClick={() => navigate(`/expert/case/${caseRequest.requestId}/overview`)}>View case</Button>
          </div>
        }
        <div className="right-wrapper">
        {navigationButtons(form)}
        </div>
      </PageFooter>
    </PageContainer>
    <PurviewFooter/>
  </>
  }</WithForm>
}
