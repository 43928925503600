
import React, {useContext, useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp';
import styled, {css} from 'styled-components';
import { Dialog,   DialogFooter, Button, Loading, Icon, DialogContent, CloseIcon } from '@startlibs/components';
import { useToggle } from '@startlibs/core'
import {UIAction} from "../../service/UIAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import { formatDate, getColor, media } from '@startlibs/utils';
import { Combobox, RadioboxGroup, TabRadiobox, TextInput } from '@startlibs/form';
import { lighten } from 'polished';
import { jwtPostFetcher } from '../../utils/authFetch';
import { UploaderAction } from '../../service/UploaderAction';
import { PatientInfoBox } from './ImportCareQualityInstitutionsDialog';
import { AdvancedSearchPopup } from './AdvancedSearchPopup';
import { Tooltip } from '@startlibs/components';



const LoadingAction = styled.div`
  color: rgba(0,0,0,0.3);
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  ${Loading} {
    margin-right: 0.5rem;
  }  
`


const RecordsDialog = styled(Dialog)`
  max-width: 90rem;
  ${DialogContent} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
  }
  ${media.desktop `
    margin-top: -2rem;
    margin-bottom: -2rem;
  `}
`

const ViewDialog = styled(Dialog)`
  max-width: 100%;
  ${DialogContent} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
  }
  ${media.desktop `
    margin: -2rem 0.5rem;
  `}
`

const DivSearchInput = styled.div`
  width:400px;
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  > input {
    padding-left: 2.5rem;
    padding-right: 9rem;
  }
  ${media.max(520)`
    width: 100%;
  `}
`

const SearchIcon = styled(Icon)`
  font-size: 20px;
  color: rgba(0,0,0,0.25);
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
`

const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`


const StyledTable = styled.div`
  overflow: auto;
  flex-grow: 1;
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${StyledTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.desktop `
    border-radius: 5px;
  `}
  ${props => props.isMobile && css `
      overflow-x: auto;
  `}
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.05);
    border-radius: 0 5px 5px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
    border-radius: 100px;
    background-clip: padding-box;
    border: 4px solid transparent;
    min-height: 30px;
    min-width: 30px;
    &:hover {
      background: rgba(0,0,0,0.35);
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }
`

const StyledTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  align-items: center;
  ${props => props.isSelected && css `
    background: ${props => lighten(0.475, props.theme.colors.success)} !important;
  `}

  ${props => props.isMobile && css `
    min-width: 100%;
  `}
  ${props => props.isEven && css `
    background-color: #EFF4F7;
  `}
}
`
export const StyledTableHead = styled(StyledTableRow)`
  max-width: 100%;
  align-items: center;
  font-weight: 600;
  background: white;
  top: 0;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  > * {
    opacity: 1 !important;
  }
  .tools {
    border-top-right-radius: 5px;
    ${props => props.isMobile && `
      height: 4rem;
    `}
  }
`
export const StyledTableColumn = styled.div`
  flex-basis: 0;
  flex-grow: 2;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  &.institution {
    flex-grow: 2;
  }
  &.record {
    flex-grow: 2;
  }
  &.type {
    flex-grow: 1;
  } 
  &.date {
    flex-grow: 1;
    width: 12px;
    flex-basis: inherit;
  }
  &.action{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 130px;
  }
  &.tools {
    flex-grow: 0;
    width: 72px;
    margin-left: 0px;
    min-width: 46px;
    flex-basis: inherit;
    text-align: right;
    position: sticky;
    right: 0rem;
    z-index: 10;
    :before {
      transition: all 0.5s ease, opacity 0ms;
      content: '';
      position: absolute;
      width: 0;
      left: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      border-right: 1px solid rgba(0,0,0,0.1);
      opacity: 0;
    }
  }
`

const LoadingTableContent = styled.div `
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  height: 100%;
  height: calc(100% - 4rem);
  max-height: 80vh;
  max-height: calc(80vh - 4rem);
  ${props => !props.isMobile && css`
    ${media.desktop `
      max-width: calc(100vw - 5rem - 15px);
      left: 2.5rem;
    `}
    ${media.mobile `
      max-width: calc(100vw - 15px);
      left: 0;
    `}
  `}
`
const EmptyList = styled.div `
  background: rgb(240,240,240);
  min-height: 8rem;
  height: calc(100% - 49px);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0;
`

const ErrorDisplay = styled.div `
  background: rgb(240,240,240);
  min-height: 8rem;
  height: calc(100% - 49px);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0;
`

const ImportButton = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: ${props => props.selected ? '1px' : '0.25rem'};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: ${props => props.selected ? getColor('success') : getColor('secondary')};
  ${Icon} {
    margin-right: 0.25rem;
    font-size: 26px;
  }
  a {
    text-decoration: underline;
    color: ${getColor('gray90')};
    font-weight: 400;
    margin-top: -3px;
    display: block;
  }
`

const ToolButtonView = styled.a`
  text-decoration: none;
  color: inherit;
  ${Icon} {
    font-size: 26px;
    color: ${getColor('secondary')};
  }   
`

// const IFRAME_ROOT = process.env.NODE_ENV === 'development' ? '../../..' : '.'
const IFRAME_ROOT = '../../..'

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          // Extract Base64 string, remove data URL header
          const base64String = reader.result
              .replace(/^data:.+;base64,/, '');
          resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}

export const ReportFrame = styled.iframe`
  width: 100%;
  // max-width: 210mm;
  min-width: 300px;
  min-height: 300px;
  border-radius: 4px;
  border: 1px solid ${getColor('gray180')};
  background: white;
  height: 100%;
  ${props => props.txtReport && css`
    background: white;
    padding: 2rem;
    border: 1px solid ${getColor('gray210')};
  `}
  ${props => (props.pdfReport || props.htmlReport) && css `
    height: 100%;
    width: 100%;
  `}
  ${props => props.htmlReport && css `
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
  ${props => props.isCCDA && css`
    max-width: 100%;
    height: 100%;
  `}
`

const ReturnButton = styled(Button)`
    box-shadow: none;
    color: ${getColor('gray60')};
    margin-right: 1rem;
`
 
const PreviewDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  color: ${getColor('gray90')};
  h2 {
    margin-bottom: 0.25rem;
    font-size: 18px;
  }
  .patientInfo {
    font-size: 14px;
  }
  .bullet {
    margin: 0 0.5rem;
  }
  ${CloseIcon} {
    position: relative;
    right: inherit;
    top: inherit;
    margin-left: 1rem;
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
    margin-right: -0.25rem;
    color: ${getColor('gray150')};
    background: ${getColor('gray240')};
    :hover {
      background: ${getColor('gray230')};
    }
  }
  .documentInfo {

  }
  .actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
  position: absolute;
  top: 10px;
  right: 10px;
`

const InstitutionsRadioBox = styled(TabRadiobox)`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ActiveFilters = styled.div `
  margin-bottom: 0.5rem;
  ${media.max(800) `
    margin-bottom: 0.5rem;
  `}
  ${media.max(625) `
    margin-bottom: 0.25rem;
  `}
  margin-top: 0.5rem;
  > span {
    margin-top: 0.25rem;
    background: rgba(0,0,0,0.075);
    border-radius: 50px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
    line-height: 2rem;
    display: inline-block;
    &.past-year {
      background: #f0f6f8;
      border: 1px solid ${getColor('main')};
      color: ${getColor('main')};
      :hover {
        background: rgba(185,200,210,0.3);
      }
      ${Icon} {
        background: rgba(0,139,210,0.125);
        :hover {
          background: #dec0c1;
          color: ${getColor('alert')};
        }
      }
    }
  }
  > span:hover {
    background: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  ${props => props.popupIsOpen && `
    opacity: 0.4;
    pointer-events: none;
  `}
  ${Icon} {
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 -0.25rem 0 0.25rem;
    text-align: center;
    display: inline-block;
    vertical-align: -1px;
    :hover {
      background: #dec0c1;
      color: ${getColor('alert')};
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .tag {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: -8px;
  }
`

function formatDateFrom14(dateStr) {
  if (!dateStr || dateStr.length < 8) return null;
  // Format the date string from "YYYYMMDDHHMMSS" to a Date object
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Month is 0-indexed
  const day = parseInt(dateStr.substring(6, 8), 10);
  const hour = dateStr.length > 8 ? parseInt(dateStr.substring(8, 10), 10) : 0;
  const minute = dateStr.length > 8 ? parseInt(dateStr.substring(10, 12), 10) : 0;
  const second = dateStr.length > 8 ? parseInt(dateStr.substring(12, 14), 10) : 0;
  
  return new Date(year, month, day, hour, minute, second);
}

export const ImportCareQualityRecordsDialog = ({data, setNotification}) => {

  const {
    requestId,
    refreshUrl,
    jwt,
    patientName,
    caseId,
    worklistViewerJwt: expertViewJwt,
    apiEndpoints:{pdfIframeSrc}
  } = useContext(UploaderConfigContext)

  const viewDocument = useToggle()
  const advancedPopup = useToggle()
  const [params, setParams] = useState({})
  const [
    selectedCarequalityInstitutionsWithPatients, 
    setSelectedCarequalityInstitutionsWithPatients
  ] = useState(data?.selectedCarequalityInstitutionsWithPatients?.map(
    i => ({
      ...i,
      isLoading: true,
      records: []
    })
  ))
  
  const doAction = useDoAction()
  const [selectedInstitution, setSelectedInstitution] = useState(selectedCarequalityInstitutionsWithPatients[0]?.id || null)
  const close = () => {
    doAction(UIAction.ToggleImportCareQualityRecordsDialog)
    doAction(UIAction.SetSelectedCarequalityInstitutions,[])
    doAction(UIAction.SetSelectedCarequalityInstitutionsWithPatients,[])
  }

  const [filteredRecords, setFilteredRecords] = useState([])
  const [searchText, setSearchText] = useState('')
  const tableRef = useRef()

  useEffect(() => {
    if(selectedInstitution && selectedCarequalityInstitutionsWithPatients){

      // setFilteredRecords(
      //   selectedCarequalityInstitutionsWithPatients.map(i => 
      //     i.id == selectedInstitution ?
      //       i?.records.filter(item =>
      //         item?.documentName?.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
      //         && (params.to && params.from ? item?.creationTime >= params.from && item?.creationTime <= params.to : true)
      //           // creationTime: "20231127000000"
      //       )
      //     : null
      //   )
      setFilteredRecords(
        selectedCarequalityInstitutionsWithPatients.map(i => 
          i.id === selectedInstitution
            ? i?.records.filter(item => {
                const itemNameLower = item?.documentName?.toLowerCase();
                const searchTextLower = searchText?.toLowerCase();
                const creationTime = item?.creationTime;
                const from = params.from ? new Date(params.from) : null;
                const to = params.to ? new Date(params.to) : null;
                const itemDate = formatDateFrom14(creationTime);
      
                return itemNameLower?.indexOf(searchTextLower) >= 0
                  && (!from || itemDate >= from)
                  && (!to || itemDate <= to);
              })
            : null
        )
        .filter(i => i)
        .flat()
      )
    }
  }, [searchText, selectedInstitution, selectedCarequalityInstitutionsWithPatients, params])

  useEffect(() => {
    if(selectedCarequalityInstitutionsWithPatients && selectedCarequalityInstitutionsWithPatients.length > 0){
      selectedCarequalityInstitutionsWithPatients.forEach((i, index) => {

        
        var localIds = i?.patients?.map(p => p?.localId)
        if(localIds?.length > 0){
        
          jwtPostFetcher(expertViewJwt())('/api/carequality/queryForDocuments', {       
            requestedOrgOid: i.endpointOid,
            organizationOid: i.oid,
            localIds: localIds,
          }).then((result) => {
            let newRecords = result.map(item => {
              return {
                ...item,
                institutionName: i.name,
                institutionId: i.id,
                institutionEndpointOid: i.endpointOid
              }
            })
            setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => {
              return selectedCarequalityInstitutionsWithPatients.map(inst => {
                if(i.id == inst.id){
                  return {
                    ...inst,
                    records: [...inst.records, ...newRecords],
                    isLoading: false,
                    error: null
                  }
                }
                return inst
              })
            })
          })
          .catch(err => {
            // setNotification({
            //   type: "alert",
            //   timeout: 5000,
            //   msg: (close) => <span>Could not fetch records for {i.name}</span>
            // })
            setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => {
              return selectedCarequalityInstitutionsWithPatients.map(inst => {
                if(i.id == inst.id){
                  return {
                    ...inst,
                    isLoading: false,
                    error: "Patient found in the institution, but there was a problem fetching records. Please try again, or contact support."
                  }
                }
                return inst
              })
            })
          })
        }else{
          setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => {
            return selectedCarequalityInstitutionsWithPatients.map(inst => {
              if(i.id == inst.id){
                return {
                  ...inst,
                  isLoading: false,
                  error: 'Patient not found within this institution. Please check and update the patient details if necessary, then try again.'
                }
              }
              return inst
            })
          })
        }
      })
    }
  }, [])

  return <RecordsDialog
    closeDialog={close}
  >
    <h2 css="display: flex; align-items: center;"><ReturnButton onlyIcon rounded color="gray240" icon="return" onClick={() => {
      doAction(UIAction.ToggleImportCareQualityRecordsDialog)
      doAction(UIAction.ToggleImportCareQualityInstitutionsDialog)
    }}/>Import from Carequality Records</h2>
   
   <PatientInfoBox><b>Patient:</b> {patientName}<span className="bullet"> • </span><b>Case:</b> {caseId}</PatientInfoBox>

   <DivSearchInput>
      <SearchIcon icon="search"/>
      <TextInput placeholder="Search records" raw value={searchText} setValue={setSearchText}/>
      {searchText && searchText.length > 0 && <ClearSearchButton icon="x" onClick={() => setSearchText("")}/>}
            {/* <LinkButton onClick={advancedPopup.open}> */}
            <LinkButton onClick={(e) => {advancedPopup.open(); e.preventDefault();}}>
              Advanced
            </LinkButton>
            {
              advancedPopup.isOpen &&
              <AdvancedSearchPopup
                focusedInput={advancedPopup.isOpen}
                closePopup={advancedPopup.close}
                setParams={setParams}
                params={params}
              />
            }
    </DivSearchInput>
    {/* {params.from && params.to && <DivSearchInput>Records from {params.from} to {params.to} <ClearSearchButton icon="x" onClick={() => setParams({})}/></DivSearchInput>} */}
    {(params.from || params.to ) && 
      <ActiveFilters popupIsOpen={advancedPopup.isOpen}>
        <b>Filtering:</b> 
        {!!params.from && !!params.to && <span onClick={() => advancedPopup.open()}>Documents between: {params.from} and {params.to}<a onClick={() => setParams({})}><Icon icon="x"/></a></span>}
        {!!params.from && !params.to && <span onClick={() => advancedPopup.open()}>Documents after: {params.from}<a onClick={() => setParams({})}><Icon icon="x"/></a></span>}
        {!params.from && !!params.to && <span onClick={() => advancedPopup.open()}>Documents before: {params.to}<a onClick={() => setParams({})}><Icon icon="x"/></a></span>}
      </ActiveFilters>
    }
    {/* <Combobox
        items={selectedCarequalityInstitutionsWithPatients}
        label="Institution"
        raw
        value={selectedInstitution}
        setValue={setSelectedInstitution}
        options={selectedCarequalityInstitutionsWithPatients.map(i => i.id)}
        getLabel={i => selectedCarequalityInstitutionsWithPatients.find(item => item.id === i)?.name}
    /> */}

    <RadioboxGroup label="Institution" wide css="margin-bottom:0.5rem;">

      {selectedCarequalityInstitutionsWithPatients && selectedCarequalityInstitutionsWithPatients.length > 0 &&
        selectedCarequalityInstitutionsWithPatients.map(i => {
          return <InstitutionsRadioBox
            raw 
            icon={i.status && i.status == "rejected" ? "warning" : null}
            label={i.name} fieldValue={i.id} value={selectedInstitution} 
            // white={isMobile()}
            setValue={setSelectedInstitution}
          />
        })
      }
    </RadioboxGroup>
       
    <StyledTable ref={tableRef} 
      isLoading={
        selectedInstitution && selectedCarequalityInstitutionsWithPatients.length > 0 &&
        selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.isLoading
      }
    >
      <StyledTableHead >
        <StyledTableColumn className="institution">Institution</StyledTableColumn>
        <StyledTableColumn className="record">Document</StyledTableColumn>
        <StyledTableColumn className="type">Type</StyledTableColumn>
        <StyledTableColumn className="date">Creation date</StyledTableColumn>
        <StyledTableColumn className="tools"> </StyledTableColumn>
        <StyledTableColumn className="action"> </StyledTableColumn>
      </StyledTableHead>
      {filteredRecords.length > 0 
        ? filteredRecords.map((record, i) => 
          <RecordRow
            record={record}
            jwt={jwt}
            isEven={i % 2 === 0}
            expertViewJwt={expertViewJwt}
            setSelectedCarequalityInstitutionsWithPatients={setSelectedCarequalityInstitutionsWithPatients}
            viewDocument={viewDocument}
            setNotification={setNotification}
            doAction={doAction}
          />
          )
        : (
          (selectedInstitution 
            && selectedCarequalityInstitutionsWithPatients.length > 0 
            && selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.status == "rejected")
            ? <ErrorDisplay>It was not possible to query for patients in this institution. Check institution details and try again.</ErrorDisplay> 
            : (selectedInstitution 
              && selectedCarequalityInstitutionsWithPatients.length > 0 
              && selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.error) 
              ? <ErrorDisplay>{selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.error}</ErrorDisplay> // Custom component or styled div for displaying errors
              : !(
                  selectedInstitution 
                  && selectedCarequalityInstitutionsWithPatients.length > 0 
                  && selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.isLoading
                ) 
                && <EmptyList>No records found</EmptyList>
          )
        
      }
      {selectedInstitution 
        && selectedCarequalityInstitutionsWithPatients.length > 0 
        && selectedCarequalityInstitutionsWithPatients.find(i => i.id === selectedInstitution)?.isLoading 
        && <LoadingTableContent><Loading absolute size={40} borderWidth={6}/></LoadingTableContent>
      }
    </StyledTable>
    <DialogFooter>
      
      <Button 
        highlight 
        onClick={() => {  
          doAction(UIAction.ToggleImportCareQualityRecordsDialog)
          // doAction(UIAction.ToggleImportCareQualityInstitutionsDialog)        
        }}>
        Done
      </Button>
    </DialogFooter>
    {viewDocument.isOpen && <ViewDialog
      closeDialog={false}
    >
      <PreviewDialogHeader>
        <div className='documentInfo'>
          <h2>{viewDocument.isOpen.documentName}</h2>
          <div className='patientInfo'>
            <b>Patient:</b> {patientName}<span className='bullet'> • </span>
            <b>Case:</b> {caseId}<span className='bullet'> • </span>
            <b>Institution: </b> {viewDocument.isOpen.institutionName}
            {viewDocument.isOpen.creationDateHour && <><span className='bullet'> • </span><b>Date:</b> {viewDocument.isOpen.creationDateHour}</>}
          </div>
        </div>
        <div className='actions'>
          {selectedCarequalityInstitutionsWithPatients
          .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.addedToCase 
            ? <ImportButton selected={true} disabled={false}>
                <Icon icon="imported" />
                <div>
                  <div>Added to case</div>
                  <a onClick={() => {
                    doAction(UploaderAction.DeleteManyByUID,[selectedCarequalityInstitutionsWithPatients
                      .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.medicalRecordID])
                    setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients =>
                      selectedCarequalityInstitutionsWithPatients.map(i => {
                        if(i.id === selectedCarequalityInstitutionsWithPatients
                          .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.institutionId){
                          return {...i,
                            records: i.records.map(r => {
                              if(r.documentId === selectedCarequalityInstitutionsWithPatients
                                .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.documentId){
                                return {...r, addedToCase: false}
                              }
                              return r
                            })
                          }
                        }
                        return i
                      })
                    )
                    setNotification({
                      type: "success",
                      timeout: 4000,
                      msg: (close) => <span>Document was removed from the case</span> 
                    })
                  }}>Remove</a>
                </div>
              </ImportButton>
              // </div><Button icon="check" success onClick={() => {
              //   doAction(UploaderAction.DeleteManyByUID,[selectedCarequalityInstitutionsWithPatients
              //     .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.medicalRecordID])
              //   setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients =>
              //     selectedCarequalityInstitutionsWithPatients.map(i => {
              //       if(i.id === selectedCarequalityInstitutionsWithPatients
              //         .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.institutionId){
              //         return {...i,
              //           records: i.records.map(r => {
              //             if(r.documentId === selectedCarequalityInstitutionsWithPatients
              //               .find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find( r => r.documentId === viewDocument.isOpen.documentId)?.documentId){
              //               return {...r, addedToCase: false}
              //             }
              //             return r
              //           })
              //         }
              //       }
              //       return i
              //     })
              //   )
              //   setNotification({
              //     type: "success",
              //     timeout: 4000,
              //     msg: (close) => <span>Document was removed from the case</span> 
              //   })
              // }}>Added to case</Button> 
            : <Button icon="import" highlight
                isLoading={
                  selectedCarequalityInstitutionsWithPatients.find(i => i.id === viewDocument.isOpen.institutionId)?.records?.find(
                    r => r.documentId === viewDocument.isOpen.documentId
                  )?.isAddingToCase
                } 
                onClick={() => {
                  setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                    selectedCarequalityInstitutionsWithPatients.map(i => {
                      if(i.id === viewDocument.isOpen.institutionId){
                        return {...i,
                          records: i.records.map(r => {
                            if(r.documentId === viewDocument.isOpen.documentId){
                              return {...r, isAddingToCase: true}
                            }
                            return r
                          })
                        }
                      }
                      return i
                    })
                  )
                  jwtPostFetcher(expertViewJwt())(`/api/carequality/addSingleDocument?storageToken=${jwt()}`, {       
                    requestedOrgOid: viewDocument.isOpen.requestedOrgOid,
                    documentName: viewDocument.isOpen.documentName,
                    mimeType: viewDocument.isOpen.mimeType,
                    document:{
                      documentId: viewDocument.isOpen.documentId,
                      repositoryId: viewDocument.isOpen.repositoryId
                    },
                    storageToken: jwt()
                    
                  }).then((response) => {

                    let recordClass = ''
                    let format = ''
                    let fileType = ''
                    let fileExtension = ''

                    if(viewDocument.isOpen.mimeType === 'image/tiff'){
                      recordClass = "Pathology"
                      format = "TIFF"
                      fileType = "image/tiff"
                      fileExtension = "tiff"
                    }
                    if(viewDocument.isOpen.mimeType === 'text/plain'){
                      recordClass = "Clinical",
                      format = "TXT"
                      fileType = "text/plain"
                      fileExtension = "txt"
                    }
                    if(viewDocument.isOpen.mimeType === 'text/xml' || viewDocument.isOpen.mimeType === 'application/xml'){
                      recordClass = "Clinical",
                      format = "CCDA"
                      fileType = "application/xml"
                      fileExtension = "xml"
                    }
                    if(viewDocument.isOpen.mimeType === 'application/pdf'){
                      recordClass = "Clinical",
                      format = "PDF"
                      fileType = "application/pdf"
                      fileExtension = "pdf"
                    }
                    const newRecord = {
                      recordUID: response.medicalRecordID,
                      recordClass: recordClass,
                      format: format,
                      fileType: fileType,
                      fileExtension: fileExtension,
                      info: {
                        type: "NonDicomFile",
                        fileName: "",
                        fileExtension: fileExtension,
                        fileType: fileType,
                        // fileSize: 
                        uid: response.uuid,
                        description: viewDocument.isOpen.documentName,
                        docType: "",
                        locationFormId: 0,
                        // uploadDate: now().getTime(),
                      },
                      metadata: {
                        
                      },
                      notes: {
                        note: "",
                      },
                      state: "Submitted",
                      stateContext: {
                        Submitted:{
                          by: "",
                          on: new Date().getTime(),
                        }
                      },
                      quarantined: false,
                      id: response.medicalRecordID,
                      caseUID: '',
                      whenCreatedEpochMilli: new Date().getTime(),
                      whenModifiedEpochMilli: new Date().getTime(),
                    }
                    if(response && response?.success == true){
                      doAction(UploaderAction.LinkCarequalityFileAsRecord,newRecord)
                      setNotification({
                        type: "success",
                        timeout: 4000,
                        msg: (close) => <span>Document was added to the case</span>
                      })
                      setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                        selectedCarequalityInstitutionsWithPatients.map(i => {
                          if(i.id === viewDocument.isOpen.institutionId){
                            return {...i,
                              records: i.records.map(r => {
                                if(r.documentId === viewDocument.isOpen.documentId){
                                  return {...r, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID}
                                }
                                return r
                              })
                            }
                          }
                          return i
                        })
                      )
                      
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                      selectedCarequalityInstitutionsWithPatients.map(i => {
                        if(i.id === viewDocument.isOpen.institutionId){
                          return {...i,
                            records: i.records.map(r => {
                              if(r.documentId === viewDocument.isOpen.documentId){
                                return {...r, isAddingToCase: false}
                              }
                              return r
                            })
                          }
                        }
                        return i
                      })
                    )
                    setNotification({
                      type: "alert",
                      timeout: 4000,
                      msg: (close) => <span>It was not possible to add the document to the case</span>
                    })
                  })
                }}>Add to case
              </Button>
        }
        <CloseIcon onClick={viewDocument.close}/>
       </div>
      </PreviewDialogHeader>
      
      {/* TIFF Viewer */}
      {viewDocument.isOpen.mimeType == 'image/tiff' && 
        <ReportFrame isCCDA src={IFRAME_ROOT + '/public/tif/index.html'}/> // Tif html viewer
      }
      
      {/* CCDA Viewer */}
      {(viewDocument.isOpen.mimeType == 'text/xml' || viewDocument.isOpen.mimeType == 'application/xml') && 
        <ReportFrame isCCDA src={IFRAME_ROOT + '/public/ccda/index.html'}/>
      }

      {/* Text Viewer */}
      {viewDocument.isOpen.mimeType === 'text/plain' && viewDocument.isOpen.data && 
        <ReportFrame txtReport as="div" dangerouslySetInnerHTML={{__html: viewDocument.isOpen.data}}/>
      }
      
      {/* PDF Viewer */}
      {viewDocument.isOpen.mimeType == 'application/pdf' && 
        <ReportFrame pdfReport src={`data:application/pdf;base64,${viewDocument.isOpen.data}`}/>
      }
    </ViewDialog>}
  </RecordsDialog>
 
}


const RecordRow = ({ record, isEven, linkRecord, providerJwt, jwt, 
  setNotification, setLoadingAction, isLoadingAction, viewerLoadUrl, storageHost, 
  expertViewJwt, setSelectedCarequalityInstitutionsWithPatients, viewDocument, doAction}) => {
  
  // const selected = linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0;
  const [isLoadingInfo,setLoadingInfo] = useState(false);

  let creationDateHour = ''
  if(record.creationTime && record.creationTime.length >= 8){
    creationDateHour = record.creationTime.slice(0, 4) + "-" + record.creationTime.slice(4, 6) + "-" + record.creationTime.slice(6, 8) 
    if((record.creationTime.slice(8, 10) == "00" && record.creationTime.slice(10, 12) == "00" && record.creationTime.slice(12, 14) == "00")
      || (record.creationTime.length == 8)){

    }else{
      creationDateHour = creationDateHour + ' ' + record.creationTime.slice(8, 10) + ":" + record.creationTime.slice(10, 12) + ":" + record.creationTime.slice(12, 14)
    }
  } 
   

  return <StyledTableRow isSelected={false} isEven={isEven}>
     <StyledTableColumn className="institution fs-exclude">
      <b>{record?.institutionName}</b>
    </StyledTableColumn>
    <StyledTableColumn className="record fs-exclude">
      <b>{record?.documentName}</b>
    </StyledTableColumn>
    <StyledTableColumn className="type fs-exclude">
      {record.mimeType ? record.mimeType : ""}
    </StyledTableColumn>
    <StyledTableColumn className="date fs-exclude">
      {creationDateHour}
      {/* {record.creationTime && record.creationTime.length >= 8 ? record.creationTime.slice(0, 4) + "-" + record.creationTime.slice(4, 6) + "-" + record.creationTime.slice(6, 8) : ""} */}
    </StyledTableColumn>
    <StyledTableColumn className="tools">
    <ToolButtonView 
      disabled={record.isLoading}
      target={"_top"} 
      // href={record.src}
      onClick={() => {
        setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
          selectedCarequalityInstitutionsWithPatients.map(i => {
            if(i.id === record.institutionId){
              return {...i,
                records: i.records.map(r => {
                  if(r.documentId === record.documentId){
                    return {...r, isLoading: true}
                  }
                  return r
                })
              }
            }
            return i
          })
        )

        // jwtPostFetcher(expertViewJwt())('/api/carequality/retrieveSingleDocument', {       
        //   requestedOrgOid: record.institutionEndpointOid,
        //   documentName: record.documentName,
        //   mimeType: record.mimeType,
        //   document:{
        //     documentId: record.documentId,
        //     repositoryId: record.repositoryId
        //   }
        // })
        fetch("/api/carequality/retrieveSingleDocument", {
          "headers": {
            "accept": "*/*",
            "authorization": `Bearer ${expertViewJwt()}`,
            "content-type": "application/json; charset=UTF-8",
          },
          "body": JSON.stringify({
            requestedOrgOid: record.institutionEndpointOid,
            documentName: record.documentName,
            mimeType: record.mimeType,
            document:{
              documentId: record.documentId,
              repositoryId: record.repositoryId
            }
          }),
          "method": "POST",
        }).then(response  => {
          if(record.mimeType === 'image/tiff' || record.mimeType === 'application/pdf'){
            return response.blob()
          }else{
            return response.text();
          }
        }).then(blob => {

          let data = blob
          if(record.mimeType === 'text/plain'){
            data = data.replace(/\n/g, "<br/>")

            viewDocument.openWith({
              requestedOrgOid: record.institutionEndpointOid,
              repositoryId: record.repositoryId,
              documentName: record.documentName,
              documentId: record.documentId,
              mimeType: record.mimeType,
              institutionName: record.institutionName,
              institutionId: record.institutionId,
              creationDateHour: creationDateHour,
              data: data
            })
          }
          
          if(record.mimeType === 'text/xml' || record.mimeType === 'application/xml'){
            localStorage.setItem('ccdaPreview', data);
            viewDocument.openWith({
              requestedOrgOid: record.institutionEndpointOid,
              repositoryId: record.repositoryId,
              documentName: record.documentName,
              documentId: record.documentId,
              mimeType: record.mimeType,
              institutionName: record.institutionName,
              institutionId: record.institutionId,
              creationDateHour: creationDateHour,
              data: data
            })
          }
   
          if(record.mimeType === 'image/tiff'){
            const url = URL.createObjectURL(blob);
            localStorage.setItem('tiffSrc', url);
            viewDocument.openWith({
              requestedOrgOid: record.institutionEndpointOid,
              repositoryId: record.repositoryId,
              documentName: record.documentName,
              documentId: record.documentId,
              mimeType: record.mimeType,
              institutionName: record.institutionName,
              institutionId: record.institutionId,
              creationDateHour: creationDateHour,
              data: ''
            })
          }

          if(record.mimeType === 'application/pdf'){
            blobToBase64(blob).then(base64String => {
              viewDocument.openWith({
                requestedOrgOid: record.institutionEndpointOid,
                repositoryId: record.repositoryId,
                documentName: record.documentName,
                documentId: record.documentId,
                mimeType: record.mimeType,
                institutionName: record.institutionName,
                institutionId: record.institutionId,
                creationDateHour: creationDateHour,
                data: base64String
              })
            }).catch(error => {
                console.error(error);
                setNotification({
                  type: "alert",
                  timeout: 4000,
                  msg: (close) => <span>It was not possible to retrieve the document</span>
                })
            });
          }
        }).catch(err => {
          console.log(err)
          setNotification({
            type: "alert",
            timeout: 4000,
            msg: (close) => <span>It was not possible to retrieve the document</span>
          })
        })
        .finally(() => {
          setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients =>
            selectedCarequalityInstitutionsWithPatients.map(i => {
              if(i.id === record.institutionId){
                return {...i,
                  records: i.records.map(r => {
                    if(r.documentId === record.documentId){
                      return {...r, isLoading: false}
                    }
                    return r
                  })
                }
              }
              return i
            })
          )
        })

      }}
        
    ><Tooltip content="View document" >{record.isLoading ? <Loading size={20} borderWidth={3}/> : <Icon icon="view"/>}</Tooltip></ToolButtonView>
  
    </StyledTableColumn>
    <StyledTableColumn className="action">
      {record.isAddingToCase ?
        <LoadingAction>
          <Loading size={18} borderWidth={3}/> Processing...
        </LoadingAction>
      : record.addedToCase ?
         <ImportButton selected={record.addedToCase} disabled={record.isAddingToCase}>
            <Icon icon="imported" />
            <div>
              <div>Added to case</div>
              <a onClick={() => {
                 doAction(UploaderAction.DeleteManyByUID,[record.medicalRecordID])
                 setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients =>
                   selectedCarequalityInstitutionsWithPatients.map(i => {
                     if(i.id === record.institutionId){
                       return {...i,
                         records: i.records.map(r => {
                           if(r.documentId === record.documentId){
                             return {...r, addedToCase: false}
                           }
                           return r
                         })
                       }
                     }
                     return i
                   })
                 )
                 setNotification({
                   type: "success",
                   timeout: 4000,
                   msg: (close) => <span>Document was removed from the case</span> 
                 })
              }}>Remove</a>
            </div>
          </ImportButton>
        : <ImportButton 
            disabled={record.isAddingToCase}
            selected={record.addedToCase}
            onClick={() => {

              if(!record.addedToCase){
                setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                  selectedCarequalityInstitutionsWithPatients.map(i => {
                    if(i.id === record.institutionId){
                      return {...i,
                        records: i.records.map(r => {
                          if(r.documentId === record.documentId){
                            return {...r, isAddingToCase: true}
                          }
                          return r
                        })
                      }
                    }
                    return i
                  })
                )
                jwtPostFetcher(expertViewJwt())(`/api/carequality/addSingleDocument?storageToken=${jwt()}`, {       
                  requestedOrgOid: record.institutionEndpointOid,
                  documentName: record.documentName,
                  mimeType: record.mimeType,
                  document:{
                    documentId: record.documentId,
                    repositoryId: record.repositoryId
                  },
                  storageToken: jwt()
                  
                }).then((response) => {

                  let recordClass = ''
                  let format = ''
                  let fileType = ''
                  let fileExtension = ''

                  if(record.mimeType === 'image/tiff'){
                    recordClass = "Pathology"
                    format = "TIFF"
                    fileType = "image/tiff"
                    fileExtension = "tiff"
                  }
                  if(record.mimeType === 'text/plain'){
                    recordClass = "Clinical",
                    format = "TXT"
                    fileType = "text/plain"
                    fileExtension = "txt"
                  }
                  if(record.mimeType === 'text/xml' || record.mimeType === 'application/xml'){
                    recordClass = "Clinical",
                    format = "CCDA"
                    fileType = "application/xml"
                    fileExtension = "xml"
                  }
                  if(record.mimeType === 'application/pdf'){
                    recordClass = "Clinical",
                    format = "PDF"
                    fileType = "application/pdf"
                    fileExtension = "pdf"
                  }
                  const newRecord = {
                    recordUID: response.medicalRecordID,
                    recordClass: recordClass,
                    format: format,
                    fileType: fileType,
                    fileExtension: fileExtension,
                    info: {
                      type: "NonDicomFile",
                      fileName: "",
                      fileExtension: fileExtension,
                      fileType: fileType,
                      // fileSize: 
                      uid: response.uuid,
                      description: record.documentName,
                      docType: "",
                      locationFormId: 0,
                      // uploadDate: now().getTime(),
                    },
                    metadata: {
                      
                    },
                    notes: {
                      note: "",
                    },
                    state: "Submitted",
                    stateContext: {
                      Submitted:{
                        by: "",
                        on: new Date().getTime(),
                      }
                    },
                    quarantined: false,
                    id: response.medicalRecordID,
                    caseUID: '',
                    whenCreatedEpochMilli: new Date().getTime(),
                    whenModifiedEpochMilli: new Date().getTime(),
                  
                  }
                  if(response && response?.success == true){
                    doAction(UploaderAction.LinkCarequalityFileAsRecord,newRecord)
                    setNotification({
                      type: "success",
                      timeout: 4000,
                      msg: (close) => <span>Document was added to the case</span>
                    })
                    setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                      selectedCarequalityInstitutionsWithPatients.map(i => {
                        if(i.id === record.institutionId){
                          return {...i,
                            records: i.records.map(r => {
                              if(r.documentId === record.documentId){
                                return {...r, isAddingToCase: false, addedToCase: true, medicalRecordID: response.medicalRecordID}
                              }
                              return r
                            })
                          }
                        }
                        return i
                      })
                    )
                    
                  }
                })
                .catch((error) => {
                  console.log(error)
                  setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients => 
                    selectedCarequalityInstitutionsWithPatients.map(i => {
                      if(i.id === record.institutionId){
                        return {...i,
                          records: i.records.map(r => {
                            if(r.documentId === record.documentId){
                              return {...r, isAddingToCase: false}
                            }
                            return r
                          })
                        }
                      }
                      return i
                    })
                  )
                  setNotification({
                    type: "alert",
                    timeout: 4000,
                    msg: (close) => <span>It was not possible to add the document to the case</span>
                  })
                })
              }else{
                // doAction(UploaderAction.DeleteManyByUID,[record.medicalRecordID])
                // setSelectedCarequalityInstitutionsWithPatients(selectedCarequalityInstitutionsWithPatients =>
                //   selectedCarequalityInstitutionsWithPatients.map(i => {
                //     if(i.id === record.institutionId){
                //       return {...i,
                //         records: i.records.map(r => {
                //           if(r.documentId === record.documentId){
                //             return {...r, addedToCase: false}
                //           }
                //           return r
                //         })
                //       }
                //     }
                //     return i
                //   })
                // )
                // setNotification({
                //   type: "success",
                //   timeout: 4000,
                //   msg: (close) => <span>Document was removed from the case</span> 
                // })
              }
            }}
          >
          {record.addedToCase ?
            <><Icon icon="import" /> Added to case</>
          : <>
            {record.isAddingToCase ? <Loading size={20} borderWidth={3} /> : 
              <>
                <Icon icon="import" />
                Add to case
              </>
            }
          </>}
        </ImportButton>
      }
    </StyledTableColumn>
  </StyledTableRow>
}
