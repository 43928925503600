
import React, {useContext, useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp';
import styled, {css} from 'styled-components';
import { Dialog,   DialogFooter, Button, Loading, Icon, ContextMenu, Li, setNotification, SplitColumnsContainer } from '@startlibs/components';
import { usePopupToggle, useToggle } from '@startlibs/core'
import {UIAction} from "../../service/UIAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import { getColor, getFetcher, media, postFetcher } from '@startlibs/utils';
import { Errors, SimpleCheckbox, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../../../../../src/javascripts/utils/validation';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { DropdownButton, DropdownIcon } from '../AttachmentBoxStyles';
import { InfoBox } from '../../../../../src/javascripts/components/InfoBox';


const InstitutionList = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  max-height: 320px; 
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background: rgba(0,0,0,0.05);
    border-radius: 0 5px 5px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.25);
    border-radius: 100px;
    background-clip: padding-box;
    border: 4px solid transparent;
    min-height: 30px;
    min-width: 30px;
    &:hover {
      background: rgba(0,0,0,0.35);
      background-clip: padding-box;
      border: 4px solid transparent;
    }
  }
`
const StyledInstitutionRow = styled.div`
  cursor: pointer;
  &:nth-child(odd) {
    background-color: #EFF4F7;
  }
  &:hover {
    background: rgba(0,139,210,0.15);
  }
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 1rem;
  &:last-child {
    border-bottom: 0px;
  }
  label {
    margin-bottom: 0;
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
    }
    ${Icon} {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
      color: ${getColor('main')};
      width: 12px;
      font-weight: 600;
    }
    ${DropdownButton} {
      background: rgba(255,255,255,0.35);
      :hover {
        background: rgba(255,255,255,0.45);
      }
      * {line-height: 0;}
    }
  `}
`

const EmptyListPlaceholder = styled.div `
  padding: 5rem 1rem;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  font-weight: 600;
  margin-top: 1.5rem;
  border: 1px solid ${getColor('gray210')};
`
const DivSearchInput = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  ${Icon} {
    font-size: 20px;
    color: rgba(0,0,0,0.25);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
  }
  input {
    padding-left: 2.5rem;
  }
  ${media.max(520)`
    width: 100%;
  `}
`
export const PatientInfoBox = styled.div`
  background: ${getColor('gray240')};
  border-radius: 5px;
  padding: 0.7rem 1rem;
  margin-bottom: 1rem;
  font-size: 13px;
  .bullet {
    margin: 0 0.5rem;
  }
`

export const ImportCareQualityInstitutionsDialog = ({data}) => {
  const {
    requestId,
    refreshUrl,
    jwt,
    worklistViewerJwt: expertViewJwt,
    patientName,
    caseId,
    // apiEndpoints:{pdfIframeSrc},
    careQualityPatient,
    adminUserEmail
  } = useContext(UploaderConfigContext)

  const doAction = useDoAction()
  const close = () => {
    doAction(UIAction.ToggleImportCareQualityInstitutionsDialog)
    doAction(UIAction.SetSelectedCarequalityInstitutions,[])
  }

  const formLoading = useToggle()
  const formRef = useRef()
  const submitRef = useRef()
  const maxLimit = 5
  
  const deleteInstitution = (institutionId, setInstitutions) => {

    return jwtPostFetcher(expertViewJwt())(`/api/organizations/${institutionId}`, {},{
      method: 'DELETE'
    }).then((response) => {
      setInstitutions((institutions) => institutions.filter((institution) => institution.id !== institutionId))
      setNotification({type:"success", timeout: 4000,msg:(close) => <span>Institution deleted successfully</span>})
    })
    .catch((exception) => {
      console.log(exception)
    })
  }

  const action = (values) => {

    if(values.id){

      return  jwtPostFetcher(expertViewJwt())(`/api/organizations/${values.id}`, 
        {
          name: values.name,
          oid: values.oid,
          state: values.state,
          city: values.city,
          zipCode: values.zipCode,
          endpointOid: values.endpointOid,
          id: values.id
        },{method: 'PUT'}
      ).then((response) => {
        setInstitutions((institutions) => institutions.map((institution) => institution.id === values.id ? values : institution))
        setNotification({type:"success", timeout: 4000,msg:(close) => <span>Institution updated successfully</span>})
      })
      .catch((exception) => {
        console.log(exception)
      })
    }
     
    return jwtPostFetcher(expertViewJwt())('/api/organizations', {
      name: values.name,
      oid: values.oid,
      state: values.state,
      city: values.city,
      zipCode: values.zipCode,
      endpointOid: values.endpointOid
    })
    .then((response) => {
      setInstitutions((institutions) => [...institutions, response])
      setNotification({type:"success", timeout: 4000,msg:(close) => <span>Institution created successfully</span>})
    })
    

  }

  const preValidation = buildValidation({
    name: [required],
    oid: [required],
    endpointOid: [required],
    state: [required],
    city: [required],
  })

  const [institutions, setInstitutions] = useState([])
  
  const newInstitutionDialog = useToggle(false)
  const [filteredInstitutions, setFilteredInstitutions] = useState(institutions)
  const [selectedInstitutions, setSelectedInstitutions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const hasError = useToggle(false)

 
  useEffect(() => {
    if(data.selectedCarequalityInstitutions && data.selectedCarequalityInstitutions.length > 0) {
      setSelectedInstitutions(data.selectedCarequalityInstitutions)
    }
  },[])

  useEffect(() => {
    setFilteredInstitutions(
      institutions.filter(item =>
        item?.name?.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
          || item?.city?.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
          || item?.state?.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
      )
      // sort alphabetically
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  }, [searchText, institutions])

  useEffect(() => {
    setIsLoading(true)
    jwtGetFetcher(expertViewJwt())('/api/organizations', {}).then(result => {
      setInstitutions(result)
    }).catch(err => {
      console.log(err)
    })      
    .finally(() => {
      setIsLoading(false)
    })

  }, [])

  const handleClick = (selected,id) => {
    const institution = institutions.find(item => item.id === id)
    if (selected) {
      setSelectedInstitutions([...selectedInstitutions, institution])
    } else {
      setSelectedInstitutions(selectedInstitutions.filter(item => item.id !== institution.id))
    }
  }

  const getPatientsForInstitutions = async (institutions) => {

    let institutionsWithPatients = [];
    setIsSubmitting(true)
    
    const fetchInstitutionsForPatients = await institutions.map(async (institution) => {
        try {
            let patients = await jwtPostFetcher(expertViewJwt())('/api/carequality/pdqueryForPatientInfo', {
                requestedOrgOid: institution.endpointOid,
                patientInfo: careQualityPatient
            });
            return {
                status: 'fulfilled',
                value: {
                    status: 'fulfilled',
                    ...institution,
                    patients: patients
                }
            };
        } catch (error) {
          
          return {
              status: 'rejected',
              value: {
                status: 'rejected',
                ...institution,
                patients: []
              }, 
              reason: error
          };
        }
    });

    Promise.allSettled(fetchInstitutionsForPatients).then(results => {
      
      const institutions = results.filter(result => result.status).map(result => result.value )
      const successfulInstitutions = results.filter(result => result.status === 'fulfilled' && result.value.status != 'rejected').map(result => result.value);
      // institutionsWithPatients = successfulInstitutions.map(result => result.value, );
      institutionsWithPatients = institutions.map(result => result.value, );
      setIsSubmitting(false)
        
        const unsuccessfulInstitutions = results.filter(result => result.value.status === 'rejected');
        
        // if(successfulInstitutions.length > 0) {
          // if(unsuccessfulInstitutions.length > 0) {
          //   setNotification({type:"alert", timeout: 10000, msg:(close) => <span>
          //     It was not possible to retrieve records for {unsuccessfulInstitutions.map(item => item.value.name).join(', ')}. 
          //   </span>})
          // }
          doAction(UIAction.SetSelectedCarequalityInstitutionsWithPatients, institutionsWithPatients)
          doAction(UIAction.ToggleImportCareQualityInstitutionsDialog)
          doAction(UIAction.ToggleImportCareQualityRecordsDialog)
        // } else {
        //   setNotification({type:"alert", timeout: 4000,msg:(close) => <span>
        //     It was not possible to retrieve records for the selected institutions
        //   </span>})
        //   setIsSubmitting(false)
        // }
        
    });
      
  }
 
  return <Dialog
    closeDialog={close}
    title={"Import from Carequality"}
  >
    <p css="font-size:14px!important;">Select the institutions to search for medical records files for this patient:</p>
    
    <PatientInfoBox><b>Name:</b> {patientName}<span className="bullet"> • </span><b>Case:</b> {caseId}</PatientInfoBox>

    <DivSearchInput>
      <Icon icon="search"/>
      <TextInput placeholder="Search institution" raw value={searchText} setValue={setSearchText}/>
    </DivSearchInput>

    {isLoading 
      ?  <Loading css="margin: 0 auto;"/>
      : filteredInstitutions.length > 0 ?
        <>
          <InstitutionList>
            {
              selectedInstitutions && filteredInstitutions.map(institution =>
                <InstitutionRow
                  selected={selectedInstitutions.findIndex(item => item === institution) >= 0} //setSelectedExpert={setSelectedExpert}
                  institution={institution}
                  handleClick={handleClick}
                  selectedInstitutions={selectedInstitutions}
                  deleteInstitution={deleteInstitution}
                  setInstitutions={setInstitutions}
                  newInstitutionDialog={newInstitutionDialog}
                  maxLimit={maxLimit}
                  adminUserEmail={adminUserEmail}
                />
              )
            }
          </InstitutionList>
          {/* TODO - maxLimit Warning */}
          {maxLimit < selectedInstitutions.length && <InfoBox lightYellow style={{marginTop: '0.75rem', marginBottom: '1.5rem'}}>Please select up to <b>{maxLimit}</b> institutions.</InfoBox>}
          
        </>
      : 
        (
          <EmptyListPlaceholder>
            {filteredInstitutions.length === 0 && searchText.length > 0 && <span>There are no institutions matching your search criteria.</span>}
            {institutions.length === 0 && <span>There are no institutions registered in this system yet.</span>}
          </EmptyListPlaceholder>
        )
      
    }

    <DialogFooter css="display:flex;">
      {adminUserEmail && adminUserEmail.includes('@purview.net') && <Button
        css="margin-right:auto;"
        highlight
        onClick={() => {
          newInstitutionDialog.toggle()
        }}
      >
        Add institution
      </Button>}
      <Button onClick={close}>Cancel</Button>
      <Button 
        isLoading={isSubmitting}
        highlight
        disabled={selectedInstitutions.length === 0 || maxLimit < selectedInstitutions.length}
        onClick={() => {
            getPatientsForInstitutions(selectedInstitutions)          
        }}>
        Next
      </Button>
    </DialogFooter>
    {newInstitutionDialog.isOpen && <WithForm
      ref={formRef}
      action={action}
      preValidation={preValidation}
      onSuccess={(values) => {
        newInstitutionDialog.close()
      }}
    onFailure={() => {
      formLoading.close()
    }}
    values={newInstitutionDialog.isOpen}
    >{form => <Dialog
      closeDialog={newInstitutionDialog.close}
      title={newInstitutionDialog.isOpen?.id ? "Edit institution" : "Add institution"}
    >

      <TextInput
        label="Institution"
        mandatory
        path="name"
        placeholder="Ex: Purview"
      />

      <SplitColumnsContainer>
        <TextInput
          name="oid"
          label="OID"
          mandatory
          path="oid"
        />
        <TextInput
          name="endpointOid"
          label="Endpoint OID"
          mandatory
          path="endpointOid"
          placeholder="urn:oid:"
        />
      </SplitColumnsContainer>

      <SplitColumnsContainer>
        <TextInput
          name="state"
          label="State"
          mandatory
          path="state"
          placeholder="Ex: CA"
        />
        <TextInput
          name="city"
          label="City"
          mandatory
          path="city"
          placeholder="Ex: LA"
        />
      </SplitColumnsContainer>

      <TextInput
        label="Zip Code"
        mandatory
        path="zipCode"
        placeholder="Ex: 12345"
      />

      <Errors />

      <DialogFooter>
        <Button
          onClick={() => {
            newInstitutionDialog.close()
          }}
        >
          Cancel
        </Button>
        <Button 
          highlight 
          isLoading={formLoading.isOpen} 
          onClick={() => {
            formLoading.wait(form.submitForm())
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>}
    </WithForm>}

  </Dialog>

  
}

const InstitutionRow = ({
  maxLimit, institution, handleClick, selectedInstitutions, deleteInstitution, setInstitutions, 
  newInstitutionDialog, adminUserEmail}) => {
  
  const contextMenu = usePopupToggle()
  const confirmDelete = useToggle()
  const selected = selectedInstitutions.findIndex(item => item.id == institution.id) >= 0
  
  return <>
  <StyledInstitutionRow selected={selected} 
    onClick={() => {
      // if(selectedInstitutions.length >= maxLimit  && !selected) return
      handleClick(!selected,institution.id)
    }}>
    <div>
      <div>
        {institution.name.length > 0 && <b>{institution.name}</b>}
        {institution.oid.length > 0 && <span> ({institution.oid})</span>}
      </div>
      <div>
        {institution.city} - {institution.state} - {institution.zipCode}
      </div>
    </div>
    
    <SimpleCheckbox
      raw
      css="margin-left:auto;"
      value={selected}
      setValue={() => {
        // if(selectedInstitutions.length >= maxLimit && !selected) return
        handleClick(!selected,institution.id)
      }}
      // disabled={selectedInstitutions.length >= maxLimit && !selected}
    />

    {adminUserEmail && adminUserEmail.includes('@purview.net') && <DropdownButton css="margin-left:0.75rem;" onClick={(e) => {
        e.stopPropagation()
        contextMenu.openWith(institution.id)
      }}>
        <DropdownIcon icon="arrow-down"/>
      {
        contextMenu.isOpen == institution.id &&
        <ContextMenu>
          <Li label="Edit" icon={"edit"} onClick={() => newInstitutionDialog.openWith(institution)}/>
          <Li label="Delete" icon={"delete"} onClick={() => {
            confirmDelete.open()
            // deleteInstitution(institution.id, setInstitutions)
          }}/>
        </ContextMenu>
      }
    </DropdownButton>}
  </StyledInstitutionRow>
  {confirmDelete.isOpen && <Dialog
    closeDialog={confirmDelete.close}
    title="Delete institution"
    footer={<>
      <Button
        onClick={() => {
          confirmDelete.close()
        }}
      >
        Cancel
      </Button>
      <Button
        highlight
        onClick={() => {
          handleClick(false,institution.id)
          deleteInstitution(institution.id, setInstitutions)
          confirmDelete.close()
        }}
      >
        Delete
      </Button>
    </>}
  >
    <p>Are you sure you want to delete this institution?</p>
  </Dialog>}
  </>

}

