import {Button, SplitColumnsContainer} from '@startlibs/components'
import {FormValue, RadioboxGroup, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {CASE_REJECTED, PENDING_REQUEST, UNDER_REVIEW, WAITING_ACCEPTANCE} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import { DATA_MISSING, DATA_MISSING_LABEL, EXPERT_DECLINED_CASE, EXPERT_DECLINED_CASE_LABEL, INSTITUTION_DECLINED_CASE, INSTITUTION_DECLINED_CASE_LABEL, IN_PERSON_VISIT, IN_PERSON_VISIT_LABEL, OTHER, OTHER_LABEL, PATIENT_DECLINED_CASE, PATIENT_DECLINED_CASE_LABEL, PATIENT_NOT_RESPOND, PATIENT_NOT_RESPOND_LABEL, TEST_CASE, TEST_CASE_LABEL, TRANSFERRED_INTERNALLY, TRANSFERRED_INTERNALLY_LABEL } from '../../enums/PredefinedReason'
import styled from 'styled-components'
import { InfoBox } from '../../components/InfoBox'

const preValidation = buildValidation({
  "predefinedReason": required,
  "details": [(v,k,values) => (values.predefinedReason === OTHER || values.predefinedReason === DATA_MISSING) && !v.length && required()],
})

const ReasonRadioBox = styled(SimpleRadiobox)`
  width: 48%;
}`

export const useArchiveDialog = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title={
      caseRequest.state === PENDING_REQUEST ? "Set case as Archived"
        : "Set case as Archived"
    }
    action={jwtFormFetcher(getJwt())("/api/admin/archiveCase?requestId="+(caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    preValidation={preValidation}
    values={{
      "predefinedReason" : "",
      "details" : "",
      "message": "",
      "declineReasonSharedPatient": false,
      "sendEmail": caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_ACCEPTANCE
    }}
    notify={
      caseRequest.state === PENDING_REQUEST ? "Case archived successfully."
        : "Case archived successfully."
    }
    confirm={<Button highlight>{caseRequest.state === PENDING_REQUEST ? 'Set as Archived' : 'Set as Archived'}</Button>}
  >
    <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p>
    <CaseRequestCard viewOnly caseRequest={caseRequest} isResults={true} />
    <p>You will still be able to access this case under "Archived cases" and "All cases".</p>
    
    {(caseRequest.state !== UNDER_REVIEW 
      && caseRequest.state !== WAITING_ACCEPTANCE
      && caseRequest.state !== CASE_REJECTED
    ) && <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
      label="Select the reason for setting this case as Archived"
      >
        <ReasonRadioBox
          fieldValue={IN_PERSON_VISIT}
          label={IN_PERSON_VISIT_LABEL}
        />
         <ReasonRadioBox
          fieldValue={DATA_MISSING}
          label={DATA_MISSING_LABEL}
        />
        <ReasonRadioBox
          fieldValue={PATIENT_NOT_RESPOND}
          label={PATIENT_NOT_RESPOND_LABEL}
        />
        <ReasonRadioBox
          fieldValue={TRANSFERRED_INTERNALLY}
          label={TRANSFERRED_INTERNALLY_LABEL}
        />
        <ReasonRadioBox
          fieldValue={PATIENT_DECLINED_CASE}
          label={PATIENT_DECLINED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={TEST_CASE}
          label={TEST_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={INSTITUTION_DECLINED_CASE}
          label={INSTITUTION_DECLINED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={OTHER}
          label={OTHER_LABEL}
        />
    </RadioboxGroup>}

    {(caseRequest.state === UNDER_REVIEW 
      || caseRequest.state === WAITING_ACCEPTANCE
      || caseRequest.state === CASE_REJECTED
    ) && <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
      label="Select the reason for setting this case as Archived"
      >
        <ReasonRadioBox
          fieldValue={PATIENT_DECLINED_CASE}
          label={PATIENT_DECLINED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={DATA_MISSING}
          label={DATA_MISSING_LABEL}
        />
        <ReasonRadioBox
          fieldValue={INSTITUTION_DECLINED_CASE}
          label={INSTITUTION_DECLINED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={TEST_CASE}
          label={TEST_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={EXPERT_DECLINED_CASE}
          label={EXPERT_DECLINED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={OTHER}
          label={OTHER_LABEL}
        />
    </RadioboxGroup>}
    <FormValue path="predefinedReason">{reason =>
      <TextInput
        textarea
        autoResize
        mandatory={reason === OTHER || reason === DATA_MISSING}
        path="details"
        label={(reason === OTHER || reason === DATA_MISSING) ? "Please add more details" :"Details"}
      />
    }</FormValue>
   
    {(caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_ACCEPTANCE) && <>
      <InfoBox lightYellow>The expert will no longer be able to access or review this case.</InfoBox>
      <ToggleCheckbox
        label={<strong>Notify expert that the case was unassigned</strong>}
        path="sendEmail"
      />
    </>}
    
    <ToggleCheckbox path="declineReasonSharedPatient" label={<strong>Share this reason with patient</strong>}/>
    {/* <p>Are you sure you want to proceed?</p> */}
  </ConfirmDialog>
)
