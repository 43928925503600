import { Button } from '@startlibs/components';
import {Fill, useEnsureRef, useRefState, useToggle} from '@startlibs/core';
import { getColor, parseDate, postFetcher } from '@startlibs/utils';
import { lighten } from 'polished';
import {useNavigate} from 'react-router'
import { useIntl } from 'react-intl';
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import load from 'little-loader'
import styled, {createGlobalStyle} from 'styled-components';
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout'
import {CategoriesBox, lazyPublicCategories} from '../admin/experts/hooks/useEditCategories'
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import {Header} from '../components/Header'
import {LogoutButton} from '../components/Navbar';
import {OptionalHeading} from '../request/forms/InsuranceDetails'
import {PENDING_REQUEST} from '../enums/CaseState'
import {PatientAndContactDetails} from '../request/PatientAndContactDetails'
import {PurviewFooter} from '../components/PurviewFooter'
import {ServiceTermsContent} from './PatientSignTerms'
import {UrgentNotice, useUrgentNoticeDismissed} from '../pages/LandingPage'
import {createCaseTransform, createLegalCaseTransform} from '../request/utils/createCaseUtils'
import {getTimezoneOffset} from '../utils/utils'
import {responseFailure} from '../utils/validation'
import {setJwt} from '../hooks/useJwt'
import {willUseSuspense} from '../hooks/useSuspense'

const formatDate = (values) => _.update('patientInfo.dob', (date) => parseDate(date, null, 'MM-dd-yyyy'), values)

const captchaPromise = new Promise((res, rej) => {
  window.onLoadCaptchResolve = res
})

const useLoadCaptcha = willUseSuspense((captchaEnabled) => {
  if (!captchaEnabled) {
    return Promise.resolve()
  }
  load("https://www.google.com/recaptcha/api.js?onload=onLoadCaptchResolve&render=explicit")
  return captchaPromise
})

const HideRecaptha = createGlobalStyle`
.grecaptcha-badge { visibility: hidden; }
`

export const RecaptchaBrandingText = styled.div`
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  margin-right: auto;
  padding-right: 1rem;
  align-self: center;
  a {
    color: inherit;
  }
`

const EmergencyDisclaimer = styled.div `
  margin: -1rem 0 1.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  background: ${props => lighten(0.48, props.theme.colors.alert)};
  border: 1px solid ${props => lighten(0.4, props.theme.colors.alert)};
  color: ${getColor('alert')};
`

const formatCategories = _.update('categories', (categories) => (categories || []).filter(_.identity).map(({id}) => id))

export const PatientCreateRequest = ({setCaseRequest, isNurse, isRefPhysician, providerInfo, onChange, formRef}) => {

  const navigate = useNavigate()
  const intl = useIntl()

  const ensuredFormRef = useEnsureRef(formRef)

  const captchaEnabled = providerInfo.captchaEnabled

  useLoadCaptcha(captchaEnabled)

  const captchaRef = useRef()
  const submitValues = useRefState()

  const captchaCallback = (captchaToken) => postFetcher('/api/createCaseRequest', intl.locale.indexOf("LEGAL") >= 0 ? createLegalCaseTransform({
    referringPhysician: {},
    ...submitValues.get().values,
    byNurse: isNurse,
    byPhysician: isRefPhysician,
    captchaToken
  }) : 
  createCaseTransform({
    referringPhysician: {},
    ...submitValues.get().values,
    byNurse: isNurse,
    byPhysician: isRefPhysician,
    captchaToken
  }))
    .then(submitValues.get().res, submitValues.get().rej)

  useEffect(() => {
    if (captchaEnabled) {
      window.grecaptcha.ready(function () {
        window.grecaptcha.render(captchaRef.current, {
          "sitekey": "6LeBfaUZAAAAAEQNeD7e0EcudKBnZJnuZAqGAZ49",
          "size": "invisible",
          callback: captchaCallback
        });
      });
    }
  }, [])

  const discardRequest = useConfirmDialog(
    <ConfirmDialog
      title="Confirm discard request"
      navigate="/access"
      confirm={<Button alert>Discard request</Button>}
    >
      <p>You are about to discard this request and the provided information.</p>
      <p>Are you sure you want to discard? Once confirmed it can not be undone.</p>
    </ConfirmDialog>
  )

  const action = (values) => new Promise((res, rej) => {
    submitValues.set({values, res, rej})
    if (captchaEnabled) {
      window.grecaptcha.execute()
      const iframe = document.querySelector("iframe[title='recaptcha challenge']")
      if (!iframe?.parentNode?.parentNode) {
        return;
      }
      const container = iframe.parentNode.parentNode;
      const observer = new MutationObserver(mutations => {
        if (container && container.style.visibility === 'hidden') {
          if (!window.grecaptcha.getResponse()) {
            rej([values, {}])
          }
          observer.disconnect()
        }
      });
      observer.observe(container, {attributes: true, attributeFilter: ['style']});
    } else {
      captchaCallback()
    }
  })

  const onSuccess = (values, {jwt, requestId, requestCode, acceptanceInfo}) => {
    // Fullstory disabled
    // window.FS.setUserVars({
    //   displayName: requestCode,
    //   requestCode : requestCode,
    //   requestId: requestId,
    //   type: "PATIENT",
    //   customer: providerInfo.name
    // })
    // var now = new Date()
    // var eventProperties = {
    //   customer_str: providerInfo.name,
    //   dateTime_date: now,
    //   requestId_int: requestId,
    //   caseId_str: requestCode
    // }
    // window.FS.event('Patient Case Created',eventProperties)
    setCaseRequest({
      ...values,
      newlyCreated: true,
      needReleases: true,
      payments: [],
      caseFlags: {requestFlags: {}},
      requestId: requestId,
      requestCode: requestCode,
      state: PENDING_REQUEST,
      acceptanceInfo: acceptanceInfo || values.acceptanceInfo || {}
    })
    setJwt(jwt)
    return isRefPhysician ? navigate("../records") :  navigate("../status")
    // return isRefPhysician ? navigate("records") :  navigate("status")
  }

  const onFailure = responseFailure((data, {status}) => {
    if(captchaEnabled){
      window.grecaptcha.reset()
    }
    if (status === 401) {
      return {
        '': "An error has occurred, please try again."
      }
    }
    if (status === 404) {
      return {
        '': "An error has occurred, please try again."
      }
    }
    if(status === 466) {
      console.log('466 Too many')
      return {
        '': "An error has occurred, please try again. (CODE: 466)"
      }
    }
  })

  const serviceTerms = useToggle()

  useEffect(() => {
    ensuredFormRef.current.clearErrors()
  },[serviceTerms.isOpen])

  const submitAndSaveTerms = ({signature, skipSignature, terms}) => {
    return action({...ensuredFormRef.current.getTransformedValues(),acceptedTerms: !skipSignature, ...(!skipSignature ? {serviceTermsInfo:  {signature,timezoneOffset:getTimezoneOffset()}} : {})})
      .then((response) =>
        onSuccess(
          ensuredFormRef.current.getTransformedValues(),
          response
        )
      )
  }

  const withServiceTerms = providerInfo.requiresAgreement && providerInfo.serviceTerms && !isNurse &&!isRefPhysician
  const [dismissed,setDismissed] = useUrgentNoticeDismissed()

  return <>
    <Fill name="Navbar-Action">
      <LogoutButton onClick={discardRequest}>Discard request</LogoutButton>
    </Fill>
    <PageContainer>
      <Header
        title="Expert opinion request"
      />
      {providerInfo.emergencyDisclaimer && !dismissed &&
        <EmergencyDisclaimer>
          {providerInfo.emergencyDisclaimer}
        </EmergencyDisclaimer>
      }
      {
        serviceTerms.isOpen &&
        <ServiceTermsContent
          isPending={true}
          onFailure={onFailure}
          action={submitAndSaveTerms}
          withNonPatientSkip
          withRecaptchaVerification={captchaEnabled}
          returnButton={<Button onClick={serviceTerms.close}>Return to request details</Button>}
        />
      }
      <div hidden={serviceTerms.isOpen}>
        <PatientAndContactDetails
          isNurse={isNurse}
          isRefPhysician={isRefPhysician}
          formRef={ensuredFormRef}
          onChange={onChange}
          onFailure={onFailure}
          action={withServiceTerms ? serviceTerms.open : ((values) => action(values)
            .then((response) => onSuccess(values, response))
          )}
          isAdmin={false}
          isPatientCreating={true}
        >
          {form => <>
            <PageFooter>
              {!withServiceTerms && captchaEnabled &&
              <RecaptchaBrandingText>
                This site is protected by reCAPTCHA and the Google <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy</a> and <a
                href="https://policies.google.com/terms"
                rel="noopener noreferrer"
                target="_blank"
              >Terms of Service</a> apply.
              </RecaptchaBrandingText>
              }
              {
                withServiceTerms
                  ? <Button highlight type="submit">Next</Button>
                  : <Button highlight isLoading={form.isLoading} type="submit">Submit</Button>
              }

            </PageFooter>
          </>}
        </PatientAndContactDetails>
      </div>
      <HideRecaptha/>
      <div
        ref={captchaRef}
        className="g-recaptcha"
      />
    </PageContainer>
    <PurviewFooter/>
  </>
}

const AddCategories = ({}) => {
  const categories = lazyPublicCategories.read()
  if (!categories.length) {
    return null
  }
  return <><SectionHeading>
    <h3>Case divisions <OptionalHeading>(Optional)</OptionalHeading></h3>
    <p>You can include division(s) so that specific division users would be able to access this case.</p>
  </SectionHeading>
    <Card css="margin-bottom: 1rem;">
      <CategoriesBox lazyCategories={lazyPublicCategories} autoFocus={false}/>
    </Card>
  </>
}
