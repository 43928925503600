import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {DISK} from "./enums/UploaderStepsManagement";
import {FileInputBoxStyle} from "./components/styled/UploaderStyled";
import {Button, ContextMenu, Icon, Li} from "@startlibs/components";
import {useDropFileEvents} from "./hooks/useDropFileEvents";
import {UploaderAction} from "./service/UploaderAction";
import {useConstant, useLazyConstant, usePopupToggle, useToggle} from "@startlibs/core";
import {LinkVivaStudies} from "./components/dialogs/LinkVivaStudies";
import {UIAction, useRegisterDropboxRef} from "./service/UIAction";
import {UploaderConfigContext, UploaderConfigProvider} from "./service/UploaderConfigContext";
import {useDoAction} from "./service/hooks/useDoAction";
import { useIsLocale } from '../../../src/javascripts/hocs/IfLocale';

styled

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

export const DropFilesBox = () => {
  const {mode, canOpenWorklist, carequalityEnabled, adminUserEmail } = useContext(UploaderConfigContext)
  const doAction = useDoAction()
  const contextMenu = usePopupToggle()
  const isLegal = useIsLocale({contains:'LEGAL'})

  const showCareQuality = (carequalityEnabled || adminUserEmail.includes('@purview.net')) && !isLegal
  const isDragAndDropAvailable = useLazyConstant(() => {
    const div = document.createElement('div')
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
  })

  const setFile = useConstant(async (event) => {
    await doAction(UploaderAction.Upload, event)
  })

  const [isDraggingOver, dragBoxRef] = useDropFileEvents((event) => {
    setFile(event)
    isDraggingOver.close()
  })
  useRegisterDropboxRef(() => dragBoxRef)

  return mode !== DISK
    ? <FileInputBoxStyle
      draggingOver={isDraggingOver.isOpen}
      ref={dragBoxRef}
      mode={mode}
    >
      {isDragAndDropAvailable
        ? <div>
          {isDraggingOver.isOpen
            ?
            <>
              <b>Drop files heres to upload them</b>
            </>
            :
            <>
              <Button.a highlight small css="margin-bottom:0.75rem;"><input
                type="file"
                multiple
                onChange={setFile}
              />Select files</Button.a>
              {!isIE && <Button.a highlight small css="margin-bottom:0.75rem;"><input
                webkitdirectory="true" type="file" multiple
                onChange={setFile}
              />Select folders or
                discs</Button.a>}
              {canOpenWorklist == true &&<Button.a highlight small withDropdown css="margin-bottom:0.75rem;" onClick={() => contextMenu.open()}>Import from
                {contextMenu.isOpen &&
                  <ContextMenu atRight className="noIcons">
                    <Li label="Purview Image" onClick={() => doAction(UIAction.ToggleImportStudiesDialog)} />
                    {showCareQuality && <Li label="Carequality" onClick={() => doAction(UIAction.ToggleImportCareQualityInstitutionsDialog)} />}
                  </ContextMenu>
                }
              </Button.a>}
             
              <div>Or drag and drop them into this box</div>

            </>
          }
        </div>
        :
        <div>
          <input type="file" multiple onChange={setFile}/>
          <b>Click here to select file(s) to upload</b>
        </div>
      }
    </FileInputBoxStyle>
    : <FileInputBoxStyle
      draggingOver={isDraggingOver.isOpen}
      ref={dragBoxRef}
      mode={mode}
    >
      <Button.a highlight css="margin-top:1rem;"><input
        webkitdirectory="true" type="file" multiple
        onChange={setFile}
      />Select disc</Button.a>
    </FileInputBoxStyle>

}